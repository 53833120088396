import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import FieldSmallComment from 'components/FieldSmallComment';
import { openPopupWindow, roundDirection } from 'helpers/generic';
import { NavLink } from 'react-router-dom';


const Table = styled.table`
	font-size: 13px;

	thead {
		tr {
			th {
				font-weight: 500;
				text-align: right;

				&:nth-child(1) {
					text-align: left;
				}

				&:nth-child(2) {
					width: 20px;
				}

				&:nth-child(3) {
					width: 100px;
				}

				&:nth-child(4) {
					width: 80px;
				}

				&:nth-child(5) {
					width: 100px;
				}

				&:nth-child(6) {
					width: 100px;
				}

				&:nth-child(7) {
					width: 20px;
				}
			}
		}
	}

	tbody {
		tr {
			&.details {
				border-bottom: 1px solid var(--bs-primary);
			}

			td {
				&:nth-child(2) {
					text-align: right;
				}

				&:nth-child(3) {
					position: relative;
					text-align: right;					
					vertical-align: middle;

					&.fixed {
						background: var(--bs-light);
						color: var(--bs-primary);

						input {
							color: var(--bs-primary);
						}
					}

					&.concepts {
						position: relative;

						&::before {
							content: 'pre-establecida';
							position: absolute;
							top: -4px;
							left: 0;
							color: red;
							width: 100%;
							height: 10px;
							font-size: 9px;
						}
					}

					input {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: none;
						border: 0;
						text-align: right;
						padding: 0 5px;
						padding-right: 15px;

						&::placeholder {
							color: var(--bs-dark);
						}
					}
				}

				&:last-of-type {
					position: relative;

					button {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border-radius: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						box-shadow: none !important;
						padding: 0;
						min-width: 0;
					}
				}
			}
		}
	}

	tfoot {
		td {
			text-align: right;
			font-weight: 600;
		}
	}
`;

const AmountPercentChanger = styled.div`
	background: white !important;
	color: #333 !important;

	label {
		font-size: 13px;
		line-height: 13px;
		text-align: center;
		display: block;
		margin-bottom: 10px;
	}

	input[type=number] {
		margin-bottom: 10px;
		text-align: center;
		border: 1px solid var(--bs-gray-300);
	}

	button {
		display: block;
		margin: 0 auto;
		padding: 2px 10px;
	}
`;

let axiosCancelToken = null;

export default function QuotasList(props) {
	const data = props.data;
	const setData = props.setData;
	const groups = data.groups;
	const totalCallback = props.totalCallback;

	const amountPercentChangerDropdownRef = useRef(null);

	let [amountPercentChange, setAmountPercentChange] = useState(null);
	let [forceReload, setForceReload] = useState(null);
	let [quotas, setQuotas] = useState([]);
	let [detailsOpened, setDetailsOpened] = useState([]);

	const calcQuotas = useCallback((dataOverride) => {
        const dataToUse = dataOverride || data;
        // Loop properties
        let properties = [];
        groups.forEach(group => {
            group.properties?.forEach(property => {
                if ( properties.findIndex((pPro) => pPro.id === property.id) === -1 ) properties.push(property);
            });
        });			

        // Calc by properties
        let calc = [];
        properties.forEach((property) => {
            // Initial data
            let propertyCalc = {
                id: property.id,
                name: property.name,
                building: property.building,
                coefficient: property.coefficient,
                payer: property[dataToUse.type + '_payer'] ?? null,
                quota: 0,
                quota_fixed: 0,
                quota_real: 0,
                total: 0,
                total_estimate: 0,
                groups: []
            };

            // Calc by distribution and expenses config
            groups.forEach(group => {
                if ( group.distribution_type === 'consumption' ) return;
                if ( group.properties.findIndex((pFind) => pFind.id === property.id) === -1 ) return;

                let groupStats = {
                    id: group.id,
                    description: group.denomination,
                    denomination: group.denomination,
                    quota: 0,
                    total: 0
                }

                group.titles?.forEach(title => {
                    title.expenses?.forEach((expense) => {
                        if ( group.distribution_type === 'equal' ) {
                            let equalCalc = (expense.amount / group.properties_ids.length) / dataToUse.receipts_issues_qty;
            
                            propertyCalc.quota_real += equalCalc;
                            propertyCalc.quota += equalCalc;
                            groupStats.quota += equalCalc;
                        }
            
                        if ( group.distribution_type === 'coefficient' ) {
                            let totalCoefficient = 0;
                            group.properties.forEach(ptcEl => {
                                let checked = group.properties_ids.findIndex(pId => pId === ptcEl.id) !== -1;
                                totalCoefficient += checked ? parseFloat(ptcEl.coefficient) : 0;
                            });
            
                            let coefficientCalc = (expense.amount * (parseFloat(property.coefficient) / totalCoefficient)) / dataToUse.receipts_issues_qty;
            
                            propertyCalc.quota_real += coefficientCalc;
                            propertyCalc.quota += coefficientCalc;
                            groupStats.quota += coefficientCalc;
                        }					
                    });
                });

                propertyCalc.groups.push(groupStats);
            });
            
            propertyCalc.total_estimate = propertyCalc.quota * dataToUse.receipts_issues_qty; 

            // Calc por amount fixed
            if ( dataToUse.receipts_amount_calculation === 'fixed' ) {
                propertyCalc.quota = parseFloat(dataToUse.receipts_amount_fixed);
            }

            // Calc por single property fixed quota (sin tener en cuenta previas)
            let propertyFixedQuota = dataToUse.properties_fixed_quotas.filter(pfqEl => pfqEl.property_id === property.id)[0]?.quota ?? 0;
            if ( propertyFixedQuota !== 0 ) {
                propertyCalc.quota = parseFloat(propertyFixedQuota);
            }

            // Round quota
            propertyCalc.quota = roundDirection(propertyCalc.quota, dataToUse.receipts_round_direction, dataToUse.receipts_round_decimals);

            // Get total
            propertyCalc.total = propertyCalc.quota * dataToUse.receipts_issues_qty;

            // Push
            calc.push(propertyCalc);
        });

        setQuotas(calc);
    }, [data, groups, forceReload]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( totalCallback ) {
			let total = parseFloat(quotas.reduce((acc, obj) => acc + obj.total, 0)).toFixed(2);
			totalCallback(total);
		}
	}, [quotas, totalCallback]);

	useEffect(() => {
		calcQuotas();
	}, [calcQuotas]);

	const setFixedQuota = (propertyId, value) => {
		let newData = {...data};

		let idx = newData.properties_fixed_quotas.findIndex(el => el.property_id === propertyId);
		if ( idx !== -1 ) newData.properties_fixed_quotas.splice(idx, 1);

		let toSave = {
			property_id: propertyId,
			quota: value
		};
		let floatValue = parseFloat(value);
		if ( !isNaN(floatValue) ) newData.properties_fixed_quotas.push({...toSave});
		setData(newData);
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	const loadQuotasConceptsCalc = () => {
        const newData = { ...data, properties_fixed_quotas: [] };
        setData(newData);
        setQuotas([]);
        calcQuotas(newData);
    };

	const loadQuotasConceptsProperties = () => {
		let newData = {...data};

		// Loop properties
		let properties = [];
		groups.forEach(group => {
			group.properties.forEach(property => {
				if ( properties.findIndex((pPro) => pPro.id === property.id) === -1 ) properties.push(property);
			});
		});		

		properties.forEach((property) => {
			// To save variables
			let existing_quotas_sum = 0;
			let concepts = {};

			// Extract quotas concepts from groups
			let quotas_concepts_from_groups = [];
			
			groups.forEach(group => {
				group.titles?.forEach((tlt) => {
					tlt.expenses?.forEach((exp) => {
						if ( exp.quotaconcept_id ) {
							quotas_concepts_from_groups.push(exp.quotaconcept_id);
						}
					});
				});
			})
			
			property.quotasconcepts.forEach((qq) => {
				if ( quotas_concepts_from_groups.indexOf(qq.id) !== -1 ) {
					existing_quotas_sum += parseFloat(qq.pivot.amount);
					concepts[qq.id] = parseFloat(qq.pivot.amount);
				}
			});

			let toSave = {
				property_id: property.id,
				quota: existing_quotas_sum,
				concepts: concepts
			};

			if ( !toSave.quota ) return;

			// Update on state
			let idx = newData.properties_fixed_quotas.findIndex(el => el.property_id === property.id);
			if ( idx !== -1 ) newData.properties_fixed_quotas.splice(idx, 1);
			newData.properties_fixed_quotas.push({...toSave});
		});

		setData(newData);
	}

	const applyAmountPercent = () => {
		if ( !amountPercentChange ) return;

		let propertiesFixedQuotas = [...data.properties_fixed_quotas];

		quotas.forEach((quota) => {
			let pfqIdx = propertiesFixedQuotas.findIndex(el => el.property_id === quota.id); 
			if ( pfqIdx !== -1 ) { // Update existing fixed quota
				let currentQuota = propertiesFixedQuotas[pfqIdx].quota;
				if ( isNaN(currentQuota) || !currentQuota ) currentQuota = quota.quota;

				let newQuota = currentQuota + (currentQuota * (amountPercentChange / 100))
				propertiesFixedQuotas[pfqIdx].quota = roundDirection(newQuota, data.receipts_round_direction, data.receipts_round_decimals);
			} else if (quota.quota) { // Add fixed quota
				propertiesFixedQuotas.push({
					property_id: quota.id,
					quota: roundDirection(quota.quota + (quota.quota * (amountPercentChange / 100)), data.receipts_round_direction, data.receipts_round_decimals)
				});
			}
		});
		setData({...data, properties_fixed_quotas: propertiesFixedQuotas});
		// setForceReload(Math.random());


		setAmountPercentChange(null);
		amountPercentChangerDropdownRef.current.querySelectorAll('.show').forEach(el => el.classList.remove('show'));
	}

	return (
		<React.Fragment>
			<LabeledFrame 
				label="Listado de cuotas"
				buttonsBackground="primary"
			>
				<div className="row">
					<div className="col-md-12">
						<FieldSmallComment className="text-primary mb-2">Este listado de cuotas es una previsión de los pagos que deberan realizar los participantes. En la previsión <u>no</u> se incluyen los gastos por consumos.</FieldSmallComment>
						<Table className="table table-sm table-bordered mb-0">
							<thead>
								<tr>
									<th>Propiedad / Pagador</th>
									<th>Coeficiente</th>
									<th>
										<div className="d-flex align-items-center justify-content-between">
											<div className="dropdown d-inline-block float-start" ref={amountPercentChangerDropdownRef}>
												<button className="btn btn-sm btn-link p-0 m-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
													<i className="bi bi-three-dots-vertical"></i>
												</button>
												<ul className="dropdown-menu">
													<li className="dropdown-item text-center px-0">
														<button 
															className="btn btn-sm w-100"
															data-bs-toggle="tooltip" 
															data-bs-placement="bottom" 
															data-bs-trigger="hover" 
															title={'Pulsa aquí para cargar las cuotas calculadas por el presupuesto'}
															onClick={loadQuotasConceptsCalc}
														>
															Cargar calculadas
														</button>
													</li>
													<li className="dropdown-item text-center px-0">
														<button 
															className="btn btn-sm w-100"
															data-bs-toggle="tooltip" 
															data-bs-placement="bottom" 
															data-bs-trigger="hover" 
															title={'Pulsa aquí para cargar las cuotas pre-establecidas añadidas en el apartado "conceptos de cuotas"'}
															onClick={loadQuotasConceptsProperties}
														>
															Cargar pre-establercidas
														</button>
													</li>
													<li className="dropdown-divider"></li>
													<li>
														<AmountPercentChanger className="dropdown-item" onClick={(e) => e.stopPropagation()}>
															<label>Aumento o bajada</label>
															<input type="number" className="form-control form-control-sm no-arrows" onChange={(e) => setAmountPercentChange(e.target.value)} value={amountPercentChange ?? ''} placeholder="porcentaje %" />
															<button className="btn btn-sm btn-primary text-white" onClick={(e) => applyAmountPercent()}>aplicar</button>
														</AmountPercentChanger>
													</li>
												</ul>
											</div>

											Cuota
										</div>
									</th>
									<th>
										Total 
										&nbsp;
										<i 
											className="bi bi-info-circle-fill text-primary" 
											data-bs-toggle="tooltip" 
											data-bs-placement="bottom" 
											title={'Este es el importe que el pagador abonará según la cuota fijada'}
										/>
									</th>
									<th>
										Cuota real 
										&nbsp;
										<i 
											className="bi bi-info-circle-fill text-primary" 
											data-bs-toggle="tooltip" 
											data-bs-placement="bottom" 
											title={'Esta es la cuota que el pagador debería abonar en cada emisión'}
										/>
									</th>
									<th>
										Total real 
										&nbsp;
										<i 
											className="bi bi-info-circle-fill text-primary" 
											data-bs-toggle="tooltip" 
											data-bs-placement="bottom" 
											title={'Este es el importe que el pagador debería abonar en total para liquidar su parte proporcional'}
										/>
									</th>
								</tr>
							</thead>
							<tbody>
								{quotas.map((el, idx) => {
									let fixed_quota = data.properties_fixed_quotas.filter(pfqEl => pfqEl.property_id === el.id)[0] ?? null;
									// if ( !fixed_quota?.quota ) fixed_quota = null;

									return (
										<React.Fragment key={idx}>
											<tr className="has-details">
												<td>
													<span className="badge bg-light text-secondary me-2">
														{el.building?.name}
													</span>
													{el.name} <NavLink to={'/properties/edit/' + el.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink> 
													<span>&nbsp; / &nbsp;</span> 
													<span className={!el.payer?.id ? 'text-danger' : ''}>
														{el.payer?.name ?? 'Sin pagador'}
													</span>
												</td>
												<td>
													{parseFloat(el.coefficient).toFixed(2)}%
												</td>
												<td className={(fixed_quota ? 'fixed ' : '') + (fixed_quota?.concepts && Object.keys(fixed_quota.concepts).length > 0 ? ' concepts' : '')}>
													<input 
														type="number" 
														className={'no-arrows'}
														step="0.01" 
														value={!isNaN(fixed_quota?.quota) ? fixed_quota?.quota : ''}
														onChange={(e) => setFixedQuota(el.id, e.target.value)} 
														placeholder={el.quota.toFixed(2)} 
													/> 
													<span>€</span>
												</td>
												<td className="text-end">
													{el.total.toFixed(2)} €
												</td>
												<td className="text-end">
													{el.quota_real.toFixed(2)} €
												</td>
												<td className="text-end">
													{el.total_estimate.toFixed(2)} €
												</td>
												<td>
													<button className="btn btn-primary text-white" onClick={() => toggleDetails(el.id)}><i className={'bi bi-caret-' + (detailsOpened.includes(el.id) ? 'up' : 'down') + '-fill'}></i></button>
												</td>
											</tr>
											{ detailsOpened.includes(el.id) && 	
												<tr className="details">
													<td colSpan="100%">
														<div className="row ms-0 me-0">
															<div className="col-md-12 small">
																{el.groups.map((gEl, gIdx) => {
																	return (
																		<div key={gIdx}>
																			<span className="fw-bold">{gEl.denomination}:</span> {gEl.quota.toFixed(2)} € por cuota / {(gEl.quota * data.receipts_issues_qty).toFixed(2)} € en total
																		</div>
																	);
																})}
															</div>
														</div>
													</td>
												</tr>
											}
										</React.Fragment>
									);
								})}
								{quotas.length === 0 && <tr><td colSpan="100%">Todavía no hay información disponible</td></tr>}
							</tbody>
							<tfoot>
								<tr>
									<th></th>
									<td>Total</td>
									<td>{parseFloat(quotas.reduce((acc, obj) => acc + obj.quota, 0)).toFixed(2)} €</td>
									<td>{parseFloat(quotas.reduce((acc, obj) => acc + obj.total, 0)).toFixed(2)} €</td>
									<td>{parseFloat(quotas.reduce((acc, obj) => acc + obj.quota_real, 0)).toFixed(2)} €</td>
									<td>{parseFloat(quotas.reduce((acc, obj) => acc + obj.total_estimate, 0)).toFixed(2)} €</td>
								</tr>
							</tfoot>
						</Table>
					</div>
				</div>
			</LabeledFrame>
		</React.Fragment>
	);
}