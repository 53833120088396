import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { formatNumber, jsonToArray, loader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';

let axiosCancelToken = null;

const CounterpartsStyled = styled.div`
    padding: 20px;

    h1 {
        text-align: center;
        font-size: 25px;
        display: block;
        margin-bottom: 20px;
    }

    h2 {
        text-align: right;
        font-size: 18px;

        b {
            font-weight: 600;
        }
    }

    table#movements {
        font-size: 13px;

        td {
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                width: 50px;
                text-align: right;
                white-space: nowrap;
            }
        }

        tbody, thead, tfoot {
            border-top-color: #dee2e6;
        }

        thead {
            &:first-of-type {
                background: var(--bs-primary);
                color: white;
            }
        }

        tfoot {
            font-weight: 600;
            background: var(--bs-gray-200);
        }

        tr {
            &.subtotal {
                font-weight: 600;
                background: var(--bs-gray-100);


                &:not(:last-of-type) {
                    
                }

                td {
                    text-align: right;
                }
            }

            &.bg-orange {
                background: rgba(var(--bs-orange-rgb), 0.5);
            }  
        }
    }
`;

export default function Counterparts(props) {
	const year = props.year;
    const setExportBtn = props.setExportBtn;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setExportBtn(<ExportBtn year={year} />);
    }, [year]);

    useEffect(() => {
        axiosCancelToken = axios.CancelToken.source();

        const getBalance = async () => {
            setLoading(true);
            setData(null);
    
            await axios.get('/api/manager/balances/counterparts', {
                params: {
                    community_id: getCommunity()?.id,
                    financial_year: year
                },
                cancelToken: axiosCancelToken.token
            }).then((response) => {
                if (response.data) {
                    setData(response.data);
                    setLoading(false);
                }
            }).catch((error) => {
                if ( axios.isCancel(error) ) return;
                setLoading(false);
            })
        }

        getBalance();

        return function cleanup() {
            axiosCancelToken.cancel();
        }
    }, [year]);    

	return (
        <>
            {loading && <div style={{textAlign: 'center'}}>{loader}</div>}
                       
            {(data !== null && !loading) && 
                <CounterpartsStyled>
                    <h1 className="title">BALANCE DE CONTRAPARTIDAS {year}</h1>

                    <table id="movements" className="table table-sm table-bordered">
                        <thead>
                            <tr>
                                <th>GASTOS POR CUENTAS</th>
                                <th>IMPORTE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jsonToArray(data?.expenses_detailed)?.map(estimate => {

                                return (
                                    <React.Fragment key={'g-estimate-'+estimate.key}>
                                        <tr key={'g-estimate-'+estimate.key} style={{background: 'var(--bs-gray-400)'}} className="fw-bold">
                                            <td>{estimate.value.name}</td>
                                            <td>{formatNumber(estimate.value.amount ?? 0, 2)} €</td>
                                        </tr>
                                        {jsonToArray(estimate?.value.groups)?.map(group => {
                                            let distributionType = null;
                                            if ( group.value.distribution_type === 'coefficient' ) distributionType = 'Coeficiente';
                                            if ( group.value.distribution_type === 'equal' ) distributionType = 'Partes iguales';
                                            if ( group.value.distribution_type === 'consumption' ) distributionType = 'Consumos';

                                            return (
                                                <React.Fragment key={'g-group-'+group.key}>
                                                    <tr style={{background: 'var(--bs-gray-200)'}} className="fw-bold">
                                                        <td style={{paddingLeft: '20px'}}>
                                                            <div className="d-flex justify-content-between">
                                                                {group.value.name}
                                                                <span className="text-uppercase badge bg-dark d-flex align-items-center">{distributionType}</span>
                                                            </div>
                                                        </td>
                                                        <td>{formatNumber(group.value.amount ?? 0, 2)} €</td>
                                                    </tr>
                                                    {jsonToArray(group?.value.titles)?.map(title => {
                                                        return (
                                                            <React.Fragment key={'g-title-'+title.key}>
                                                                <tr style={{background: '#F6993F'}} className="fw-bold">
                                                                    <td style={{paddingLeft: '40px'}}>{title.value.name}</td>
                                                                    <td>{formatNumber(title.value.amount ?? 0, 2)} €</td>
                                                                </tr>
                                                                {jsonToArray(title?.value.expenses)?.map(expenses => {
                                                                    return (
                                                                        <React.Fragment key={'g-expenses-'+expenses.key}>
                                                                            <tr style={{background: '#f8f9fa73'}} className="fw-bold">
                                                                                <td style={{paddingLeft: '60px'}}>{expenses.value.name}</td>
                                                                                <td>{formatNumber(expenses.value.amount ?? 0, 2)} €</td>
                                                                            </tr>
                                                                            {expenses?.value?.movements?.map(movement => {
                                                                                return (
                                                                                    <React.Fragment key={'g-expenses-movements'+movement.key}>
                                                                                        <tr>
                                                                                            <td style={{paddingLeft: '80px'}}>
                                                                                                <span className="badge bg-light text-secondary">{movement.type === 'providerinvoice' && 'F'}{movement.type === 'counterpart' && 'C'}</span>&nbsp;
                                                                                                {movement.name}
                                                                                            </td>
                                                                                            <td>{formatNumber(movement.amount ?? 0, 2)} €</td>
                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                );
                                                                            })}
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </CounterpartsStyled>
            }
        </>
	);
};

const ExportBtn = (props) => {
    const {year} = props;
    
    const exportClick = async () => {
		await axios.post('api/manager/balances/export/counterparts', {
			community_id: getCommunity()?.id,
			financial_year: year
		}, {
			responseType: 'blob',
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let fileName = response.headers.file_name;

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
    }

    return (
        <button className="btn btn-sm btn-light ms-2" onClick={exportClick}>Exportar</button>	
    );
}