import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { formatNumber, loader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import Table2Pdf from 'components/Table2Pdf';

const StyledReport = styled.div`
    padding-top: 20px;

    h1 {
        text-align: center;
        font-size: 25px;
        display: block;
        margin-bottom: 20px;
    }

    table {
        thead {
            tr {
                th {
                    text-transform: uppercase;
                    vertical-align: bottom;
                    font-size: 12px;
                    line-height: 12px;
                    text-align: center;
                    font-weight: normal;

                    &#property {
                        width: 250px;
                        min-width: 250px;
                    }

                    &#coefficient {
                        width: 20px;
                    }

                    &#initial_balance {
                        width: 50px;
                    }

                    &#expenses {
                        width: 80px;
                    }

                    &#paid {
                        width: 80px;
                    }

                    &#refunded {
                        width: 80px;
                    }

                    &#final_balance {
                        width: 50px;
                    }

                    &.estimate_expense {
                        position: relative;
                        padding-top: 25px;

                        span {
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: block;
                            width: 100%;
                            font-size: 10px;
                            font-weight: normal;
                            margin-top: 3px;
                        }

                        small {
                            display: block;
                            font-size: 9px;
                            line-height: 9px;
                            color: gray;
                            margin-top: 5px;
                        }

                        b {
                            display: block;
                            margin-top: 5px;
                            font-weight: 600;
                            font-size: 10px;
                            line-height: 10px;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    text-align: center;

                    &:nth-child(1) {
                        text-align: left;
                    }
                }
            }
        }

        tfoot {
            tr {
                td {
                    text-align: center;
                    font-weight: bold;
                }
            }
        }
    }

`;

let pageTitleEl = null;
let pageContentEl = null;
let layoutEl = null;
let cardBalanceEl = null;
let cardBalanceBodyEl = null;

let layoutElOverflowOriginal = null;
let pageTitleElPositionOriginal = null;
let pageContentElPaddingOriginal = null;
let pageContentElOverflowOriginal = null;
let cardBalanceElHeightOriginal = null;
let cardBalanceBodyElOverflowOriginal = null;

let axiosCancelToken = null;

const ExpensesDistribution = (props) => {
	const year = props.year;
    const setExportBtn = props.setExportBtn;
    
    useEffect(() => {
        setExportBtn(<ExportBtn tableRef={tableRef} year={year} />);
    }, [year]);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        axiosCancelToken = axios.CancelToken.source();

        const getBalance = async () => {
            setLoading(true);
            setData(null);
    
            await axios.get('/api/manager/balances/expenses-distribution', {
                params: {
                    community_id: getCommunity()?.id,
                    financial_year: year
                },
                cancelToken: axiosCancelToken.token
            }).then((response) => {
                if (response.data) {
                    setData(response.data);
                    setLoading(false);
                }
            }).catch((error) => {
                if ( axios.isCancel(error) ) return;
                setLoading(false);
            })
        }

        getBalance();

        return function cleanup() {
            axiosCancelToken.cancel();
        }
    }, [year]);    

    const tableRef = useRef(null);

    
    // Fix scrolls
    useEffect(() => {
        pageTitleEl = document.querySelector('.page-title');
        pageContentEl = document.querySelector('.page-content');
        layoutEl = document.querySelector('#layout');
        cardBalanceEl = document.querySelector('#card-balance');
        cardBalanceBodyEl = cardBalanceEl.querySelector('.card-body');
        
        const scrollFix = (e) => {
            let windowHeight = window.innerHeight;

            // Remove layout overflow scroll
            if ( !layoutElOverflowOriginal ) layoutElOverflowOriginal = layoutEl.style.overflow;
            layoutEl.style.overflow = 'hidden';

            // Remove title sticky
            if ( !pageTitleElPositionOriginal ) pageTitleElPositionOriginal = pageTitleEl.style.position;
            pageTitleEl.style.position = 'unset';

            // Remove padding
            if ( !pageContentElPaddingOriginal ) pageContentElPaddingOriginal = pageContentEl.style.padding;
            pageContentEl.style.padding = 0;
            if ( !pageContentElOverflowOriginal ) pageContentElOverflowOriginal = pageContentEl.style.overflow;
            pageContentEl.style.overflow = 'hidden';

            // Calc height
            let pageTitleBounding = pageTitleEl.getBoundingClientRect();
            let height = windowHeight - pageTitleBounding.top - pageTitleBounding.height;
            pageContentEl.style.height = height + 'px';
            // console.log(height)

            // Set overflow to card balance
            if ( !cardBalanceElHeightOriginal ) cardBalanceElHeightOriginal = cardBalanceEl.style.height;
            cardBalanceEl.style.height = '100%';
            if ( !cardBalanceBodyElOverflowOriginal ) cardBalanceBodyElOverflowOriginal = cardBalanceBodyEl.style.overflow;
            cardBalanceBodyEl.style.overflow = 'scroll';
        }
        window.addEventListener('resize', scrollFix);
        scrollFix();
        
        return () => {
            window.removeEventListener('resize', scrollFix);

            // Reset all changes
            layoutEl.style.overflow = layoutElOverflowOriginal;
            pageTitleEl.style.position = pageTitleElPositionOriginal;
            pageContentEl.style.padding = pageContentElPaddingOriginal;
            pageContentEl.style.overflow = pageContentElOverflowOriginal;
            cardBalanceEl.style.height = cardBalanceElHeightOriginal;
            cardBalanceBodyEl.style.overflow = cardBalanceBodyElOverflowOriginal;
        }
    }, []);

    let totals = {
        coefficient: 0,
        initial_balance: 0,
        groups_expenses: {},
        total_expenses: 0,
        total_paid: 0,
        total_refunded: 0,
        final_balance: 0
    };

	return (
        <>
            {loading && <div style={{textAlign: 'center'}}>{loader}</div>}
            
            {(data !== null && !loading) && 
                <StyledReport>
                    <h1 className="title">DISTRIBUCIÓN DE GASTOS {year}</h1>
                        <table className="table table-bordered" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th id="property">PROPIEDAD</th>
                                    <th id="coefficient">COEF.</th>
                                    <th id="initial_balance">SALDO INICIAL</th>
                                    {data?.groups_expenses?.map((el, idx) => {
                                        return (
                                            <th className="estimate_expense" key={idx}>
                                                <span>{el.estimate_name}</span>
                                                <small>{el.title_name}</small>
                                                <b>{el.denomination}</b>
                                            </th>
                                        )
                                    })}
                                    {data?.groups_expenses?.length === 0 &&
                                        <th>No hay distribución de gastos</th>
                                    }
                                    <th id="expenses">TOTAL GASTOS</th>
                                    <th id="paid">GASTO PAGADO</th>
                                    {/* <th id="refunded">DEVUELTO</th> */}
                                    <th id="final_balance">SALDO FINAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.properties?.map((el, idx) => {

                                    totals.coefficient += parseFloat(el.coefficient);
                                    totals.initial_balance += parseFloat(data?.initial_balances[el.id] ?? 0);
                                    totals.total_expenses += data?.total_expenses_by_properties[el.id] ?? 0;
                                    totals.total_paid += data?.total_paid_by_properties[el.id] ?? 0;
                                    totals.total_refunded += data?.total_refunded_by_properties[el.id] ?? 0;
                                    totals.final_balance += data?.final_balances[el.id] ?? 0;

                                    return (
                                        <tr key={idx}>
                                            <td>{el.name}</td>
                                            <td>{formatNumber(el.coefficient, 2)}</td>
                                            <td>{formatNumber(data?.initial_balances[el.id] ?? 0, 2)}</td>
                                            {data?.groups_expenses?.map((gEl, gIdx) => {
                                                if ( !totals.groups_expenses['ge'+gEl.id] ) totals.groups_expenses['ge'+gEl.id] = 0;
                                                totals.groups_expenses['ge'+gEl.id] += parseFloat(data?.expenses_by_properties[el.id][gEl.id] ?? 0);

                                                return (
                                                    <td className="estimate_expense" key={gIdx}>{formatNumber(data?.expenses_by_properties[el.id][gEl.id] ?? 0, 2)}</td>
                                                )
                                            })}
                                            {data?.groups_expenses?.length === 0 &&
                                            <td></td>
                                            }
                                            <td>{formatNumber(data?.total_expenses_by_properties[el.id] ?? 0, 2)}</td>
                                            <td>{formatNumber((data?.total_paid_by_properties[el.id] ?? 0) + (data?.total_refunded_by_properties[el.id] ?? 0), 2)}</td>
                                            {/* <td>{formatNumber(data?.total_paid_by_properties[el.id] ?? 0, 2)}</td> */}
                                            {/* <td>{formatNumber(data?.total_refunded_by_properties[el.id] ?? 0, 2)}</td> */}
                                            <td>{formatNumber(data?.final_balances[el.id] ?? 0, 2)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>TOTAL</td>
                                    <td>{formatNumber(totals.coefficient, 2)}</td>
                                    <td>{formatNumber(totals.initial_balance, 2)}</td>
                                    {totals.groups_expenses && Object.keys(totals.groups_expenses).map((el, idx) => {
                                        return (
                                            <td key={idx}>{formatNumber(totals.groups_expenses[el], 2)}</td>
                                        );
                                    })}
                                    <td>{formatNumber(totals.total_expenses, 2)}</td>
                                    <td>{formatNumber(totals.total_paid, 2)}</td>
                                    {/* <td>{formatNumber(totals.total_refunded, 2)}</td> */}
                                    <td>{formatNumber(totals.final_balance, 2)}</td>
                                </tr>
                            </tfoot>
                        </table>
                </StyledReport>
            }
        </>
	);
}

export default ExpensesDistribution;

const ExportBtn = (props) => {
    const {tableRef, year} = props;

    const pdfExporterRef = useRef(null);
    
    const exportExcel = async () => {
        await axios.post('api/manager/balances/export/expenses-distribution', {
            community_id: getCommunity()?.id,
            financial_year: year
        }, {
            responseType: 'blob',
            cancelToken: axiosCancelToken.token
        }).then((response) => {
            let fileName = response.headers.file_name;
    
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentElement.removeChild(link);
        }).catch((error) => {
            if ( axios.isCancel(error) ) return;
        });

        console.log('sasas')
    }

    const exportPdf = () => {
        pdfExporterRef.current.export();
    }

    return (
        <div className="dropdown ms-2">
            <button 
                className="btn btn-sm btn-light dropdown-toggle" 
                type="button" 
                id="exportDropdown" 
                data-bs-toggle="dropdown" 
                aria-expanded="false">
                Exportar&nbsp;
            </button>
            <ul className="dropdown-menu" aria-labelledby="exportDropdown">
                <li>
                    <button className="dropdown-item" onClick={() => exportExcel()}>
                        Exportar Excel
                    </button>
                </li>
                <li>
                    <button className="dropdown-item" onClick={() => exportPdf()}>
                        Exportar PDF
                    </button>
                    <Table2Pdf 
                        ref={pdfExporterRef}
                        tableRef={tableRef}
                        button={<></>}
                        pageOrientation="landscape"
                        pdfName={'distribución-gastos-' + year}
                    />
                </li>
            </ul>
        </div>
    );
}