import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import moment from 'moment';
import { openPopupWindow } from 'helpers/generic';
import { NavLink } from 'react-router-dom';

const ProvidersWrapper = styled.div`

	table {

		thead {

			th {
				font-weight: 500;

				&:nth-child(1) {
					
				}

				&:nth-child(2) {
					width: 150px;
				}
				
				&:nth-child(3) {
					width: 150px;
				}

				&:nth-child(4) {
					width: 40px;
				}
			}
		}

		tbody {
			border-top-color: var(--bs-gray-200) !important;

			td {
				height: 30px;

				&.has-errors {
					background: rgba(255, 0, 0, 0.2);
				}

				&:nth-child(1) {
					position: relative;

					.empowered-selector {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;

						.dropdown {
							height: 100%;

							.empowered-selector_label {
								background: transparent;
							}
						}
					}
				}

				&:nth-child(2),
				&:nth-child(3) {
					position: relative;

					& > input {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						padding: 5px 10px;
						background: transparent;
						resize: none;
						line-height: 100%;

						@media (min-width: 963px) {
							border: 0;
						}

						&[type=number] {
							text-align: right;
						}
					}
				}

				&:nth-child(4) {
					position: relative;

					& > button {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border: 0;
						background: var(--bs-white);
						color: var(--bs-red);
						font-size: 20px;
						display: flex;
						justify-content: center;
						align-items: center;

						&[disabled] {
							opacity: 0.5;
						}

						&:hover {
							&:not([disabled]) {
								background: var(--bs-red);
								color: var(--bs-white);
							}
						}
					}
				}
			}
		}
	}
`;

let axiosCancelToken = null;

const providerTemplate = {
	id: null,
	requested_at: moment().format('YYYY-MM-DD'),
	accepted_at: null,
};

export default function Providers(props) {
	const data = props.data;
	const setData = props.setData;
	const errors = props.errors;
	
	let readOnly = props.readOnly ?? false;

	let lastRow = useRef(null);
	let lastRowNumber = useRef(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( lastRow.current ) {
			lastRow.current.focus();
			lastRow.current = null;
		}
	}, [data]);

	
	const addProvider = () => {
		let newData = {...data};
		let newProvider = JSON.parse(JSON.stringify(providerTemplate)); // Deep clone
		if( !newData.providers ) newData.providers = [];
		newData.providers.push({...newProvider});
		setData(newData);

		lastRowNumber.current = newData.providers.length - 1; // Needed to focus
	}

	const removeProvider = (idx) => {
		let newData = {...data};
		let newProviders = [...newData.providers];
		newProviders.splice(idx, 1);
		newData.providers = newProviders

		setData(newData);
	}

	const setProviderField = (idx, field, value) => {
		let newData = {...data};
		let newProviders = [...newData.providers];
		let provider = {...newProviders[idx]};

		provider[field] = value;

		newProviders[idx] = provider;
		newData.providers = newProviders;

		setData(newData);
	}

	const loadProviders = (input, callback) => {
		axios.get('/api/manager/providers/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectProvider = (selection, idx) => {
		let newData = {...data};
		let newProviders = [...newData.providers];
		let provider = {...newProviders[idx]};

		provider['provider'] = selection;
		provider['provider_id'] = selection?.id;

		newProviders[idx] = provider;
		newData.providers = newProviders;

		setData(newData);
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	return (
		<LabeledFrame 
			label={'Proveedores'}
			buttons={
				!readOnly ?
					<button className="btn-unstyled text-white" onClick={() => addProvider()}>
						<i className="bi bi-plus-circle-fill"></i>
					</button>
				:
				null
			}
			buttonsBackground={'primary'}
		>
			<ProvidersWrapper>
				{/* <div className="table-responsive"> */}
					<table className="table table-sm table-bordered mb-0">
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Fecha de solicitud</th>
								<th>Fecha de aceptación</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{ data.providers && data.providers.map((el, idx) => {
								return (
									<tr key={idx}>
										<td className={' ' + (errors['providers.'+idx+'.provider_id'] ? 'has-errors' : '')}>
											<EmpoweredSelector
												load={loadProviders}
												onChange={(value) => selectProvider(value, idx)}
												timeout={250}
												label={
													<div>
														{el.provider?.name ?? ''}&nbsp;
														{el.provider?.id &&
															<NavLink className="text-decoration-none text-reset" to={'/providers/edit/' + data.community.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)}><i className="bi bi-eye text-primary"></i></NavLink>
														}
													</div>
												}
												placeholder="Proveedor"
												showPlaceholderHelper={false}
												value={el.provider_id}
												disabled={readOnly} 
												noRemovable={true}
											/>
										</td>
										<td className={' ' + (errors['providers.'+idx+'.requested_at'] ? 'has-errors' : '')}>
											<input readOnly={readOnly} type="date" value={el.requested_at ?? ''} onChange={(e) => setProviderField(idx, 'requested_at', e.target.value)} />
										</td>
										<td className={' ' + (errors['providers.'+idx+'.accepted_at'] ? 'has-errors' : '')}>
											<input readOnly={readOnly} type="date" value={el.accepted_at ?? ''} onChange={(e) => setProviderField(idx, 'accepted_at', e.target.value)} />
										</td>
										<td>
											<button disabled={readOnly} onClick={() => removeProvider(idx)}><i className="bi bi-x"></i></button>
										</td>
									</tr>
								);
							})}

							{ !data.providers?.length && 
								<tr><td colSpan="100%">No se han añadido proveedores</td></tr>
							}
						</tbody>
					</table>
					{errors['providers'] && <div className="text-danger">{errors['providers']}</div>}
				{/* </div> */}
			</ProvidersWrapper>
		</LabeledFrame>
	);
}