import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import PopupLayout from "layouts/PopupLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import EmpoweredSelector from 'components/EmpoweredSelector';
import { getCommunity } from 'helpers/community';
import { formatNumber } from 'helpers/generic';
import { getFromSession, appendToSession } from 'helpers/session';

const Table = styled.table`
	th,td {
		
		&:nth-child(2) {
			width: 180px;
		}

		&:nth-child(4) {
			width: 80px;
			white-space: nowrap;
		}

		&:nth-child(5) {
			width: 80px;
			text-align: right;
			white-space: nowrap;
		}

		&:nth-child(6) {
			text-align: right;
			white-space: nowrap;
		}

		&:nth-child(7) {
			width: 80px;
			text-align: right;
		}

		&:nth-child(8) {
			width: 80px;
			text-align: center;
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function ProvidersEstimates() {
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const searchQS = queryParams.get('search') ?? undefined;

	let filters = getFromSession('providers-estimates-list');
	
	let [forceReload, setForceReload] = useState(null);
	let [estimates, setEstimates] = useState({});
	let [search, setSearch] = useState(searchQS ?? (filters?.search ?? ''));
	let [dateFrom, setDateFrom] = useState(filters?.date_from ?? moment().startOf('year').format('YYYY-MM-DD'));
	let [dateTo, setDateTo] = useState(filters?.date_to ?? moment().endOf('year').format('YYYY-MM-DD'));
	let [community, setCommunity] = useState(filters?.community ??{
		id: getCommunity()?.id,
		name: getCommunity()?.name
	});
	let [provider/*, setProvider*/] = useState(filters?.provider ?? {
		id: null,
		name: null
	});
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'desc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'date');
	let [detailsOpened, setDetailsOpened] = useState(filters?.detailsOpened ?? []);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? (filters?.page ?? 1));
	const setPage = (page) => {
		setEstimates({...estimates, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
		} else {
			detailsOpened = [...detailsOpened, rowId];
		}

		setDetailsOpened([...detailsOpened]);
		appendToSession('providers-estimates-list', {detailsOpened: detailsOpened});
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getEstimates = async () => {
			let params = {
				page: 				page,
				search: 			search,
				community_id: 		community?.id,
				provider_id: 		provider?.id,
				sort: 				sortField,
				direction: 			sortDirection,
				date_from: 			dateFrom,
				date_to: 			dateTo
			};

			appendToSession(
				'providers-estimates-list', 
				{
					...params,
					community: {...community},
					// category: {...category},
					provider: {...provider}
				}
			);

			await axios.get('/api/manager/providers-estimates/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setEstimates({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getEstimates();
	}, [forceReload, page, search, community, provider, sortField, sortDirection, dateFrom, dateTo]);

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const getDateRange = (range) => {
		let dateFrom = null;
		let dateTo = null;
		
		switch(range) {
			case 'thismonth':
				dateFrom = moment().startOf('month').format('YYYY-MM-DD');
				dateTo = moment().endOf('month').format('YYYY-MM-DD');
			break;

			case 'lastmonth':
				dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;

			case 'thisyear':
				dateFrom = moment().startOf('year').format('YYYY-MM-DD');
				dateTo = moment().endOf('year').format('YYYY-MM-DD');
			break;

			case 'lastyear':
				dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
			break;
			default: 
		}

		return {dateFrom, dateTo};;
	}

	const setDates = (e, range) => {
		e.preventDefault();

		let {dateFrom, dateTo} = getDateRange(range);
		
		setDateFrom(dateFrom);
		setDateTo(dateTo);
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<section>
				<div className="page-title">
					<h1>Solicitudes de presupuestos</h1>
					
					<div className="d-flex ms-auto">
						<NavLink to={'/providers-estimates/add' + (popup ? '?popup=true' : '')} className="btn btn-sm btn-light ms-2">Nueva solicitud</NavLink>
					</div>
					
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadCommunities}
										onChange={(value) => setCommunity({id: value?.id, name: value?.name})}
										timeout={250}
										label={community?.name}
										placeholder="- Comunidad -"
										value={community?.id}
									/>
								</div>
								<div className="col-md-4 mb-2 mb-md-0 text-end">
									<div className="input-group input-group-sm">
										<span className="input-group-text">
											Fecha
										</span>
										<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
										<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
										<span className="input-group-btn">
											<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
											<ul className="dropdown-menu dropdown-menu-end">
												<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'thismonth')}>Este mes</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'lastmonth')}>Mes pasado</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'thisyear')}>Este año</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'lastyear')}>Año pasado</a></li>
											</ul>
										</span>
									</div>
								</div>
								<div className="col-md-2 mb-2 mb-md-0 text-end">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" defaultValue={search ?? ''} onChange={(e) => setSearchTimeout(e.target.value)} value={searchQS} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 text-end">
									
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'community.name'} onClick={() => sortTableClick('community.name')}>Comunidad</ThSortable>
											<th>Proveedores</th>
											<ThSortable direction={sortDirection} active={sortField === 'number.full'} onClick={() => sortTableClick('number.full')}>Nº</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'providers_estimates.date'} onClick={() => sortTableClick('providers_estimates.date')}>Fecha</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'providers_estimates.base'} onClick={() => sortTableClick('providers_estimates.base')}>Base</ThSortable>
											<th>Impuestos</th>
											<ThSortable direction={sortDirection} active={sortField === 'providers_estimates.total'} onClick={() => sortTableClick('providers_estimates.total')}>Total</ThSortable>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ estimates.data?.length > 0 &&
											estimates.data.map((el, idx) => {
												return ( 
													<React.Fragment key={'p'+idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>
																{el.community?.name ?? '-'}
																<div><small>{el.community?.vatnumber}</small></div>
															</td>
															<td>
																{el.providers?.map((iEl, iIdx) => {
																	return (
																		<div key={iIdx}>
																			{iEl.provider?.business_name ?? '-'}
																			<div><small>{iEl.provider?.vatnumber}</small></div>
																			{iIdx < el.providers.length - 1 && <hr />}
																		</div>
																	);
																})}
															</td>
															<td>
																{ el.number_full }
															</td>
															<td>{ el.date ? moment(el.date).format('DD-MM-YYYY') : '-' }</td>
															<td>{ formatNumber(el.base) } €</td>
															<td>
																{el.taxes?.map((iEl, iIdx) => {
																	return <div key={iIdx}>{iEl.name}: {formatNumber(iEl.amount, 2)} €</div>
																})}
															</td>
															<td>{ formatNumber(el.total) } €</td>
															<td>
																<NavLink className="btn-table" to={'/providers-estimates/edit/' + el.id + (popup ? '?popup=true' : '')}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<div className="col-md-6">
																			{/* <div><b className="sbold">Proveedor</b>: {el.provider?.business_name ?? '-'}</div> */}
																			<div><b className="sbold">Nº documento</b>: {el.number_full ?? '-'}</div>
																			<div><b className="sbold">Fecha</b>: {el.date ? moment(el.date).format('DD-MM-YYYY') : '-'}</div>
																			<div><b className="sbold">Base imponible</b>: { formatNumber(el.base) } €</div>
																			<div><b className="sbold">Total</b>: { formatNumber(el.total) } €</div>
																		</div>
																		<div className="col-md-6">

																		</div>
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ estimates.data && !estimates.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ estimates.data === undefined && <TrSkeleton rows={skeletonRows} columns={8} /> }
									</tbody>
									<tfoot>
										<tr className="sbold">
											<td colSpan="6"></td>
											<td className="text-end">{ formatNumber(estimates.total_sum) } €</td>
											<td></td>
										</tr>
									</tfoot>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={estimates?.current_page}
									max={estimates?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}


