import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import PopupLayout from "layouts/PopupLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import EmpoweredSelector from 'components/EmpoweredSelector';
import { getCommunity, authUserPermission } from 'helpers/community';
import { formatNumber, downloadFile, openPopupWindow } from 'helpers/generic';
import { getFromSession, appendToSession } from 'helpers/session';
import ExportModal from 'components/ExportModal';
import BulkUploadButton from './BulkUploadButton';
import BulkDragAndDropUpload from './BulkDragAndDropUpload';
import DraftInvoices from './DraftInvoices';
import BulkActionsDropdown from './BulkActionsDropdown';

const Table = styled.table`
	th,td {
		&:nth-child(2) {
			width: 180px;
		}
		
		&:nth-child(3) {
			width: 180px;
		}
	
		&:nth-child(4) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(5) {
			width: 80px;
			text-align: right;
			white-space: nowrap;
		}

		&:nth-child(6) {
			text-align: right;
			white-space: nowrap;
		}

		&:nth-child(7) {
			width: 80px;
			text-align: center;
		}

		&:nth-child(8),
		&:nth-child(9) {
			width: 80px;
			text-align: center;
		}


		@media (max-width: 768px) {
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(8),
			&:nth-child(9),
			&:nth-child(10) {
				display: none;
			}
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function ProvidersInvoices() {
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const searchQS = queryParams.get('search') ?? undefined;

	let filters = getFromSession('providers-invoices-list');
	
	let [forceReload, _setForceReload] = useState(null);
	let setForceReload = (value, resetSelected = true) => {
		_setForceReload(value);
		if (resetSelected) {
			setSelected([]);
		}
	}
	let [invoices, setInvoices] = useState({});
	let [selected, setSelected] = useState([]);
	let [search, setSearch] = useState(searchQS ?? (filters?.search ?? ''));
	let [dateFrom, setDateFrom] = useState(filters?.date_from ?? moment().startOf('year').format('YYYY-MM-DD'));
	let [dateTo, setDateTo] = useState(filters?.date_to ?? moment().endOf('year').format('YYYY-MM-DD'));
	let [dateReceptionFrom, setDateReceptionFrom] = useState(filters?.date_reception_from ?? null);
	let [dateReceptionTo, setDateReceptionTo] = useState(filters?.date_reception_to ?? null);
	let [community, setCommunity] = useState(filters?.community ??{
		id: getCommunity()?.id,
		name: getCommunity()?.name
	});
	let [category, setCategory] = useState(filters?.category ??{
		id: null,
		name: null
	});
	let [sector, setSector] = useState(filters?.sector ??{
		id: null,
		name: null
	});
	let [paymentmethod, setPaymentmethod] = useState(filters?.paymentmethod ??{
		id: null,
		name: null
	});
	let [provider/*, setProvider*/] = useState(filters?.provider ?? {
		id: null,
		name: null
	});
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'desc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'date');
	let [detailsOpened, setDetailsOpened] = useState(filters?.detailsOpened ?? []);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? (filters?.page ?? 1));
	const setPage = (page) => {
		setInvoices({...invoices, data: undefined});
		_setPage(page);
	}
	let [paymentStatus, setPaymentStatus] = useState(filters?.paid_status ?? null);
	let [accountingStatus, setAccountingStatus] = useState(filters?.accounted ?? null);
	let [showExportModal, setShowExportModal] = useState(false);


	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
		} else {
			detailsOpened = [...detailsOpened, rowId];
		}

		setDetailsOpened([...detailsOpened]);
		appendToSession('providers-invoices-list', {detailsOpened: detailsOpened});
	}

	useEffect(() => {
		window.PopupProxyCallback = (data) => {
			if (data && data.type && data.type === 'refreshCounterPartsAndPayments') {
				setForceReload(Math.random(), false);
			}
		}
	}, []);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getInvoices = async () => {
			let params = {
				page: 				page,
				search: 			search,
				community_id: 		community?.id,
				// category_id: 		category?.id,
				sector_id: 			sector?.id,
				paymentmethod_id: 	paymentmethod?.id,
				provider_id: 		provider?.id,
				sort: 				sortField,
				direction: 			sortDirection,
				accounted: 			accountingStatus,
				paid_status: 		paymentStatus,
				date_from: 			dateFrom,
				date_to: 			dateTo,
				date_reception_from:dateReceptionFrom,
				date_reception_to:	dateReceptionTo,
			};

			appendToSession(
				'providers-invoices-list', 
				{
					...params,
					community: {...community},
					// category: {...category},
					sector: {...sector},
					paymentmethod: {...paymentmethod},
					provider: {...provider}
				}
			);

			await axios.get('/api/manager/providers-invoices/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setInvoices({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getInvoices();
	}, [forceReload, page, search, community, /*category,*/, sector, paymentmethod, provider, sortField, sortDirection, accountingStatus, paymentStatus, dateFrom, dateTo, dateReceptionFrom, dateReceptionTo]);

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadCategories = (input, callback) => {
		axios.get('/api/manager/expenses-categories/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadSectors = (input, callback) => {
		axios.get('/api/manager/sectors/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadPaymentmethods = (input, callback) => {
		axios.get('/api/manager/payment-methods/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const viewDocument = (e, file) => {
		e.preventDefault();

		const baseUrl = e.currentTarget.href;
		if ( file.viewable ) return openPopupWindow(baseUrl + file.id);

		return downloadFile('/api/manager/documents/download-file/' + file.id);
	}

	const getDateRange = (range) => {
		let dateFrom = null;
		let dateTo = null;
		
		switch(range) {
			case 'thismonth':
				dateFrom = moment().startOf('month').format('YYYY-MM-DD');
				dateTo = moment().endOf('month').format('YYYY-MM-DD');
			break;

			case 'lastmonth':
				dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;

			case 'thisyear':
				dateFrom = moment().startOf('year').format('YYYY-MM-DD');
				dateTo = moment().endOf('year').format('YYYY-MM-DD');
			break;

			case 'lastyear':
				dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
			break;
			default: 
		}

		return {dateFrom, dateTo};;
	}

	const setDates = (e, range) => {
		e.preventDefault();

		let {dateFrom, dateTo} = getDateRange(range);
		
		setDateFrom(dateFrom);
		setDateTo(dateTo);
	}

	const setDateReceptions = (e, range) => {
		e.preventDefault();

		let {dateFrom, dateTo} = getDateRange(range);
		
		setDateReceptionFrom(dateFrom);
		setDateReceptionTo(dateTo);
	}

	const toggleCheckbox = (rowNumber) => {
		let newSelected = [...selected];

		let idx = newSelected.indexOf(rowNumber);
		if ( idx === - 1 ) newSelected.push(rowNumber);
		else newSelected.splice(idx, 1);

		setSelected(newSelected);
	}

	const toggleAllCheckbox = () => {
		if ( selected.length === invoices.data?.length ) setSelected([]);
		else setSelected(invoices.data?.map((el, idx) => idx));
	}

	const openBankReconciliation = (e, pinvoice) => {
		e.preventDefault();

		const url = e.target.href + '?popup=true&community_id=' + pinvoice.community_id;

		openPopupWindow(url, 'Conciliación bancaria');
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<BulkDragAndDropUpload 
				community={community} 
				permissions={authUserPermission('add')}
				parentReload={() => setForceReload(Math.random())} 
			>
				<section>
					<div className="page-title">
						<h1>Facturas recibidas</h1>
						
						<div className="d-flex ms-auto">
							<BulkUploadButton 
								community={community} 
								parentReload={() => setForceReload(Math.random())} 
							/>
							<NavLink to={'/providers-invoices/add' + (popup ? '?popup=true' : '')} className="btn btn-sm btn-light ms-2">Nueva factura</NavLink>
						</div>
						
					</div>

					<div className="page-content">
						<DraftInvoices 
							community={community} 
							parentReload={() => setForceReload(Math.random())} 
							reload={forceReload}
						/>

						<div className="card border-0 shadow-sm">
							<div className="card-header bg-white p-3">
								<div className="row">
									<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
										<EmpoweredSelector
											load={loadCommunities}
											onChange={(value) => setCommunity({id: value?.id, name: value?.name})}
											timeout={250}
											label={community?.name}
											placeholder="- Comunidad -"
											value={community?.id}
										/>
									</div>
									<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
										{/* <EmpoweredSelector
											load={loadCategories}
											onChange={(value) => setCategory({id: value?.id, name: value?.name})}
											timeout={250}
											label={category?.name}
											placeholder="- Categoría -"
											value={category?.id}
										/> */}
										<EmpoweredSelector
											load={loadSectors}
											onChange={(value) => setSector({id: value?.id, name: value?.name})}
											timeout={250}
											label={sector?.name}
											placeholder="- Sector -"
											value={sector?.id}
										/>
									</div>
									<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
										<EmpoweredSelector
											load={loadPaymentmethods}
											onChange={(value) => setPaymentmethod({id: value?.id, name: value?.name})}
											timeout={250}
											label={paymentmethod?.name}
											placeholder="- Método de pago -"
											value={paymentmethod?.id}
										/>
									</div>
									<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
										<select className="form-control form-control-sm" value={paymentStatus ?? ''} onChange={(e) => setPaymentStatus(e.target.value)}>
											<option value="">- Estado del pago -</option>
											<option value="paid">Pagada</option>
											<option value="partial">Parcial</option>
											<option value="unpaid">No pagada</option>
											<option value="manual">Pago manual</option>
										</select>
									</div>
									<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
										<select className="form-control form-control-sm" value={accountingStatus ?? ''} onChange={(e) => setAccountingStatus(e.target.value)}>
											<option value="">- Contabilizada -</option>
											<option value="1">Si</option>
											<option value="0">No</option>
										</select>
									</div>
									<div className="col-md-4 mb-2 mt-2 mb-md-0 text-end">
										<div className="input-group input-group-sm">
											<span className="input-group-text">
												Fecha factura
											</span>
											<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
											<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
											<span className="input-group-btn">
												<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
												<ul className="dropdown-menu dropdown-menu-end">
													<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'thismonth')}>Este mes</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'lastmonth')}>Mes pasado</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'thisyear')}>Este año</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'lastyear')}>Año pasado</a></li>
												</ul>
											</span>
										</div>
									</div>
									<div className="col-md-4 mb-2 mt-2 mb-md-0 text-end">
										<div className="input-group input-group-sm">
											<span className="input-group-text">
												Fecha contable
											</span>
											<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateReceptionFrom ?? ''} onChange={(e) => setDateReceptionFrom(e.target.value)} />
											<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateReceptionTo ?? ''} onChange={(e) => setDateReceptionTo(e.target.value)} />
											<span className="input-group-btn">
												<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
												<ul className="dropdown-menu dropdown-menu-end">
													<li><a className="dropdown-item" href="." onClick={(e) => setDateReceptions(e, 'thismonth')}>Este mes</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDateReceptions(e, 'lastmonth')}>Mes pasado</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDateReceptions(e, 'thisyear')}>Este año</a></li>
													<li><a className="dropdown-item" href="." onClick={(e) => setDateReceptions(e, 'lastyear')}>Año pasado</a></li>
												</ul>
											</span>
										</div>
									</div>
									<div className="col-md-2 mb-2 mt-2 mb-md-0 text-end">
										<input type="search" className="form-control form-control-sm" placeholder="Buscar" defaultValue={search ?? ''} onChange={(e) => setSearchTimeout(e.target.value)} value={searchQS} />
									</div>
									<div className="col-md-2 mb-2 mt-2 mb-md-0 text-end">
										<button className="btn btn-sm btn-light" onClick={() => setShowExportModal(true)}>Exportar</button>

										{selected.length > 0 &&
											<BulkActionsDropdown 
												selectedItems={invoices.data?.map((el, idx) => selected.indexOf(idx) !== -1 ? el : null).filter(el => el != null)}
												parentForceReload={() => {
													setSelected([]);
													setForceReload(Math.random());
												}}
											/>
										}
									</div>
								</div>
							</div>
							<div className="card-body p-0">
								<div className="table-responsive table-responsive-carded">
									<Table className="table table-hover table-sortable table-carded">
										<thead>
											<tr>
												<th>
													<input 
														type="checkbox"
														onChange={() => toggleAllCheckbox()} 
														checked={invoices.data?.length > 0 && selected.length === invoices.data?.length} 
													/>
												</th>
												<ThSortable direction={sortDirection} active={sortField === 'community.name'} onClick={() => sortTableClick('community.name')}>Comunidad</ThSortable>
												<ThSortable direction={sortDirection} active={sortField === 'provider.name'} onClick={() => sortTableClick('provider.name')}>Proveedor</ThSortable>
												<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.number'} onClick={() => sortTableClick('providers_invoices.number')}>Nº</ThSortable>
												<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.date'} onClick={() => sortTableClick('providers_invoices.date')}>Fecha</ThSortable>
												<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.base'} onClick={() => sortTableClick('providers_invoices.base')}>Base</ThSortable>
												<th>Impuestos</th>
												<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.total'} onClick={() => sortTableClick('providers_invoices.total')}>Total</ThSortable>
												<th>Pagada</th>
												<th>Contabilizada</th>
												<ThSortable direction={sortDirection} active={sortField === 'providers_invoices.date_reception'} onClick={() => sortTableClick('providers_invoices.date_reception')}>Fecha contable</ThSortable>
												<th style={{width: '40px'}}></th>
											</tr>
										</thead>
										<tbody>
											{ invoices.data?.length > 0 &&
												invoices.data.map((el, idx) => {
													return ( 
														<React.Fragment key={'p'+idx}>
															<tr className="has-details" onClick={() => toggleDetails(el.id)}>
																<td>
																	<input 
																		type="checkbox" 
																		onClick={(e) => e.stopPropagation()} 
																		onChange={(e) => toggleCheckbox(idx)} 
																		checked={selected.indexOf(idx) !== -1} 
																	/>
																</td>
																<td>
																	{el.community?.name ?? '-'}
																	<div><small>{el.community?.vatnumber}</small></div>
																</td>
																<td>
																	{el.provider?.business_name ?? '-'}
																	<div><small>{el.provider?.vatnumber}</small></div>
																</td>
																<td>
																	{ el.number }
																	{ !el.file_id && 
																		<div className="text-danger small">Fichero pendiente de adjuntar</div>
																	}
																</td>
																<td>{ el.date ? moment(el.date).format('DD-MM-YYYY') : '-' }</td>
																<td>{ formatNumber(el.base) } €</td>
																<td>
																	{el.taxes?.map((iEl, iIdx) => {
																		return <div key={iIdx}>{iEl.name}: {formatNumber(iEl.amount, 2)} €</div>
																	})}
																</td>
																<td>{ formatNumber(el.total) } €</td>
																<td>
																	{ el.paid_status === 'manual' && <span className="badge bg-success">Manual</span>}
																	{ el.paid_status === 'full' && <span className="badge bg-success">Si</span>}
																	{ el.paid_status === 'unpaid' && <span className="badge bg-danger">No</span>}
																	{ el.paid_status === 'partial' && <span className="badge bg-indigo">Parcial</span>}
																</td>
																<td>
																	{ el.expenseaccountingentry ? <span className="badge bg-success">Si</span> : <span className="badge bg-danger">No</span> }
																</td>
																<td>{ el.date_reception ? moment(el.date_reception).format('DD-MM-YYYY') : '-' }</td>

																<td>
																	<NavLink className="btn-table" to={'/providers-invoices/edit/' + el.id + (popup ? '?popup=true' : '')}><i className="bi bi-pencil"></i></NavLink>
																</td>
															</tr>
															{ detailsOpened.includes(el.id) && 	
																<tr className="details">
																	<td colSpan="100%">
																		<div className="row ms-0 me-0">
																			<div className="col-md-6">
																				<div><b className="sbold">Proveedor</b>: {el.provider?.business_name ?? '-'}</div>
																				<div><b className="sbold">Nº documento</b>: {el.number ?? '-'}</div>
																				<div><b className="sbold">Fecha</b>: {el.date ? moment(el.date).format('DD-MM-YYYY') : '-'}</div>
																				<div className="mb-3"><b className="sbold">Fecha de recepción</b>: {el.date_reception ? moment(el.date_reception).format('DD-MM-YYYY') : '-'}</div>

																				<div><b className="sbold">Base imponible</b>: { formatNumber(el.base) } €</div>
																				<div><b className="sbold">Total</b>: { formatNumber(el.total) } €</div>
																				<div>
																					<b className="sbold">Pagada</b>:&nbsp; 
																					{ el.paid_status === 'manual' && <span className="badge bg-success">Manual</span>}
																					{ el.paid_status === 'full' && <span className="badge bg-success">Si</span>}
																					{ el.paid_status === 'unpaid' && <span className="badge bg-danger">No</span>}
																					{ el.paid_status === 'partial' && <span className="badge bg-indigo">Parcial</span>}
																				</div>
																				<div>
																					<b className="sbold">Contabilizada</b>:&nbsp; 
																					{ el.counterparts?.length > 0 ? <span className="badge bg-success">Si</span> : <span className="badge bg-danger">No</span> }
																					<NavLink to={'/bank-reconciliation'} disabled={!el.community_id} className={'btn btn-link p-0 ms-3' + (!el.community?.id ? ' disabled' : '')} onClick={e => openBankReconciliation(e, el)}>Abrir conciliación bancaria <i className="bi bi-arrow-left-right"></i></NavLink>
																				</div>
																			</div>
																			<div className="col-md-6">
																				<div><b className="sbold">Forma de pago</b>: {el.paymentmethod?.name ?? '-'}</div>
																				{/* <div><b className="sbold">Categoría</b>: {el.category?.name ?? '-'}</div> */}
																				<div><b className="sbold">Sector</b>: {el.sector?.name ?? '-'}</div>
																				<div><b className="sbold">Descripción</b>: {el.description ?? '-'}</div>
																				<div><b className="sbold">Documento asociado</b>: {el.file ? <NavLink to="/documents/view-file/" onClick={(e) => viewDocument(e, el.file)}>{el.file.name}</NavLink> : '-'}</div>
																			</div>
																		</div>
																	</td>
																</tr>
															}
														</React.Fragment>
													);
												})
											}

											{ invoices.data && !invoices.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

											{ invoices.data === undefined && <TrSkeleton rows={skeletonRows} columns={12} /> }
										</tbody>
										<tfoot>
											<tr className="sbold">
												<td colSpan="5"></td>
												<td className="text-end">{ formatNumber(invoices.total_sum) } €</td>
												<td colSpan="4"></td>
											</tr>
										</tfoot>
									</Table>
								</div>
							</div>
							<div className="card-footer p-3 d-flex justify-content-end">
								<div className="d-inline-block">
									<Paginator
										min={1}
										current={invoices?.current_page}
										max={invoices?.last_page}
										changeCallback={(page) => setPage(page)}
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
			</BulkDragAndDropUpload>
			{ showExportModal &&
				<ExportModal
					exportKey={'providers-invoices'}
					fileName={'Gastos ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						number:						'Nº factura',
						date: 						'Fecha factura',
						date_reception: 			'Fecha contable',
						provider: 					'Proveedor',
						total: 						'Total',
						paid_status: 				'Pagada',
						accounted: 					'Contabilizada'
					}}
					filters={{
						page: 						page,
						search: 					search,
						community_id: 				community?.id,
						// category_id: 				category?.id,
						sector_id: 					sector?.id,
						paymentmethod_id: 			paymentmethod?.id,
						provider_id: 				provider?.id,
						sort: 						sortField,
						direction: 					sortDirection,
						accounted: 					accountingStatus,
						paid_status: 				paymentStatus,
						date_from: 					dateFrom,
						date_to: 					dateTo,
						no_paginate:				true
					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
		</Layout>
	);
}


