import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import {formatNumber, loader} from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import { svgElementToB64Png } from 'helpers/images';
import Incomes from './Incomes';
import Expenses from './Expenses';
import Morosity from './Morosity';
import IncomesPending from './IncomesPending';

const ShortStyled = styled.div`
    padding: 20px;

    h1 {
        text-align: center;
        font-size: 25px;
        display: block;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 22px;

        b {
            font-weight: 600;
        }
    }

    table#movements {
        font-size: 13px;

        td {
            &:nth-child(2),
            &:nth-child(3) {
                width: 50px;
                text-align: right;
                white-space: nowrap;
            }
        }

        tfoot {
            font-weight: 600;
        }
    }
`;

let axiosCancelToken = null;

export default function Short(props) {
	const year = props.year;
	const setExportBtn = props.setExportBtn;

    const [loading, setLoading] = useState(false);
    const [exportChartsFix, setExportChartsFix] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        setExportBtn(<ExportBtn year={year} setExportChartsFix={setExportChartsFix} colors={data?.colors} />);
    }, [year, data?.colors]);

    useEffect(() => {
        axiosCancelToken = axios.CancelToken.source();

        const getBalance = async () => {
            setLoading(true);
            setData(null);
    
            await axios.get('/api/manager/balances/short', {
                params: {
                    community_id: getCommunity()?.id,
                    financial_year: year
                },
                cancelToken: axiosCancelToken.token
            }).then((response) => {
                if (response.data) {
                    setData(response.data);
                    setLoading(false);
                }
            }).catch((error) => {
                if ( axios.isCancel(error) ) return;
                setLoading(false);
            })
        }

        getBalance();

        return function cleanup() {
            axiosCancelToken.cancel();
        }
    }, [year]);    

    // Calc totals
    let initial_balance_total = parseFloat(data?.initial_balance?.receipts_pending ?? 0) + parseFloat(data?.initial_balance?.providers_pending ?? 0);
    // let final_balance_total = parseFloat(data?.final_balance?.receipts_pending ?? 0) + parseFloat(data?.final_balance?.providers_pending ?? 0);

    return (
        <>
            {loading && <div style={{textAlign: 'center'}}>{loader}</div>}

            {(data !== null && !loading) && 
                <ShortStyled className="row">
                    <div className="col-md-12">
                        <h1 className="title">BALANCE RESUMIDO EJERCICIO FISCAL {year}</h1>
                    </div>

                    <div className="col-md-12 mb-3">
                        <table id="movements" className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th>SALDO INICIAL A { moment(year + '-01-01').format('DD-MM-YYYY') }</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.initial_balance?.treasuries?.map((el, idx) => {
                                    initial_balance_total += parseFloat(el.amount ?? 0);

                                    return (
                                        <tr key={idx}>
                                            <td>{el.name}</td>
                                            <td></td>
                                            <td>{formatNumber(el.amount ?? 0, 2, true)} €</td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td>Recibos pendientes de cobro</td>
                                    <td></td>
                                    <td>{formatNumber(data?.initial_balance?.receipts_pending ?? 0, 2, true)} €</td>
                                </tr>
                                <tr>
                                    <td>Facturas pendientes de pago</td>
                                    <td></td>
                                    <td>{formatNumber(data?.initial_balance?.providers_pending ?? 0, 2, true)} €</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td>TOTAL SALDO INICIAL</td>
                                    <td>{formatNumber(initial_balance_total, 2, true)} €</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    
                    <div className="col-md-12">
                        <div className="row align-items-center">
                            <Incomes 
                                data={data}
                                exportChartFix={exportChartsFix}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row align-items-center">
                            <Morosity 
                                data={data}
                                exportChartFix={exportChartsFix}
                            />
                        </div>
                    </div>
                    
                    <div className="col-md-12">
                        <div className="row align-items-start">
                            <Expenses 
                                data={data} 
                                exportChartFix={exportChartsFix}
                            />
                        </div>
                    </div>

                    {/* <div className="col-md-12">
                        <div className="row align-items-center">
                            <IncomesPending 
                                data={data}
                                exportChartFix={exportChartsFix}
                            />
                        </div>
                    </div> */}

                    <div className="col-md-12 mt-5 mb-3">
                        <table id="movements" className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th>
                                        SALDO FINAL A&nbsp;
                                        {(() => {
                                            let date = null;
                                            if ( parseInt(year) === parseInt(moment().format('YYYY')) ) {
                                                date = moment().format('YYYY-MM-DD');
                                            } else {
                                                date = year + '-12-31';
                                            }
                                            return moment(date).format('DD-MM-YYYY');
                                        })()}
                                    </th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.final_balance?.treasuries?.map((el, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{el.name}</td>
                                            <td></td>
                                            <td>{formatNumber(el.amount ?? 0, 2, true)} €</td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td>Recibos pendientes de cobro</td>
                                    <td></td>
                                    <td>{formatNumber(data?.final_balance?.receipts_pending ?? 0, 2, true)} €</td>
                                </tr>
                                <tr>
                                    <td>Facturas pendientes de pago</td>
                                    <td></td>
                                    <td>{formatNumber(data?.final_balance?.providers_pending ?? 0, 2, true)} €</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td>TOTAL SALDO CONTABLE</td>
                                    <td>
                                        {(() => {
                                            let balance = 0;

                                            data?.final_balance?.treasuries?.map((el, idx) => {
                                                balance += parseFloat(el.amount ?? 0);
                                            })

                                            balance += data?.final_balance?.receipts_pending ?? 0;

                                            balance += data?.final_balance?.providers_pending ?? 0;

                                            return formatNumber(balance, 2, true);
                                        })()}
                                        €
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    {/* <div className="col-md-12 mt-3 text-end">
                        <h2 className="mb-0 d-inline-block bg-light px-2">
                            SALDO: 
                            
                            €
                        </h2>     
                    </div> */}
                </ShortStyled>
            }
        </>
	);
}

const ExportBtn = (props) => {
    const {year, setExportChartsFix, colors} = props;
    
    const exportClick = async () => {
        // Fix to disable the charts selection
		setExportChartsFix(Math.random());
		await new Promise(resolve => setTimeout(resolve, 1000));

		// Check if exists charts to send to backend
		let charts = document.querySelectorAll('[data-piecharwrapper]');
		let chartsImg = [];
		if ( charts.length > 0 ) {
			for await (let chart of charts) {
				let svg = chart.querySelector('svg');
				let b64png = await svgElementToB64Png(svg);

				chartsImg.push({
					id: chart.getAttribute('data-piecharwrapper'),
					b64png: b64png
				});
			}
		}

		// Disable fix
		setExportChartsFix(null);

		await axios.post('api/manager/balances/export/short', {
			community_id: getCommunity()?.id,
			financial_year: year,
			charts_images: chartsImg,
			colors: colors
		}, {
			responseType: 'blob',
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let fileName = response.headers.file_name;

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
    }

    return (
        <button className="btn btn-sm btn-light ms-2" onClick={exportClick}>Exportar</button>	
    );
}