import React, { useState, forwardRef, useImperativeHandle } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CristalLoader } from 'helpers/generic';

const Table2Pdf = forwardRef((props, ref) => {
    const tableRef = props.tableRef;
    const customButton = props.button;
    const pageOrientation = props.pageOrientation ?? 'portrait'; // 'portrait' o 'landscape'
    const pdfName = props.pdfName;

    const [loading, setLoading] = useState(false);

    const generatePDF = async () => {
        const input = tableRef.current;

        // Definir márgenes en milímetros para todos los lados
        const marginLeft = 10;
        const marginTop = 10;
        const marginRight = 10;
        const marginBottom = 10;

        setLoading(true);
        await html2canvas(input, { scale: 1.3 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 0.8);
            // Crear el PDF usando la orientación indicada (portrait o landscape)
            const pdf = new jsPDF({
                orientation: pageOrientation,
                unit: 'mm',
                format: 'a4'
            });
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            // Área de impresión disponible (respetando márgenes)
            const effectivePdfWidth = pdfWidth - marginLeft - marginRight;
            const effectivePdfHeight = pdfHeight - marginTop - marginBottom;

            // Factor de escala para ajustar la imagen al ancho efectivo del PDF
            const scaleFactor = effectivePdfWidth / canvas.width;
            const fullImgHeightOnPDF = canvas.height * scaleFactor;

            // Si la imagen completa cabe en una sola página, se agrega directamente
            if (fullImgHeightOnPDF <= effectivePdfHeight) {
                pdf.addImage(imgData, 'JPEG', marginLeft, marginTop, effectivePdfWidth, fullImgHeightOnPDF);
            } else {
                // Si no cabe en una sola página, se divide la imagen en slices.
                // Este algoritmo se aplica tanto para portrait como para landscape.
                let currentY = 0;
                while (currentY < canvas.height) {
                    // Calcular la altura del slice (en coordenadas del canvas) que quepa en la página PDF
                    const sliceHeight = Math.min(canvas.height - currentY, effectivePdfHeight / scaleFactor);
                    // Crear un canvas temporal para el slice
                    const pageCanvas = document.createElement("canvas");
                    pageCanvas.width = canvas.width;
                    pageCanvas.height = sliceHeight;
                    const ctx = pageCanvas.getContext("2d");

                    // Dibujar la porción correspondiente del canvas original
                    ctx.drawImage(
                        canvas,
                        0, currentY, canvas.width, sliceHeight,
                        0, 0, canvas.width, sliceHeight
                    );

                    const pageImgData = pageCanvas.toDataURL('image/jpeg', 0.8);

                    if (currentY > 0) pdf.addPage();
                    // Agregar el slice al PDF respetando el margen superior en cada página
                    pdf.addImage(pageImgData, 'JPEG', marginLeft, marginTop, effectivePdfWidth, sliceHeight * scaleFactor);

                    currentY += sliceHeight;
                }
            }

            pdf.save((pdfName ?? 'captura-pantalla') + '.pdf');
        });
        setLoading(false);
    };

    useImperativeHandle(ref, () => ({
        export: generatePDF
    }));

    return (
        <>
            {loading && <CristalLoader />}
            {!customButton &&
                <button 
                    className="btn btn-link" 
                    onClick={generatePDF}
                >
                    <i className="bi bi-file-earmark-pdf"></i>
                </button>
            }
            {customButton && React.cloneElement(customButton, { onClick: generatePDF })}
        </>
    );
});

export default Table2Pdf;