import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import FieldSmallComment from 'components/FieldSmallComment';
import { openPopupWindow, jsonToArray } from 'helpers/generic';

const TableStyled = styled.table`
	width: 100%;
	font-size: 12px;

	thead {
		th {
			font-weight: normal;
			line-height: 30px;
			cursor: pointer;
			
			&:not(:nth-child(1)) {
				text-align: center;
			}

			&:nth-child(2),
			&:nth-child(3) {
				width: 40px;
			}

			&:nth-child(4),
			&:nth-child(5) {
				width: 70px;
			}
		}
	}

	tbody {
		td {
			line-height: 15px;

			&:not(:nth-child(1)) {
				text-align: center;
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				text-align: center;
			}

			input[type=checkbox] {
				width: 15px;
				height: 15px;
			}	
		}
	}
`;

let axiosCancelToken = null;

export default function PermissionsTable(props) {

	let [communities, setCommunities] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getCommunities = async () => {
			await axios.get('/api/manager/communities/list', {
				params: {
					no_paginate: true,
					status: true,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setCommunities([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getCommunities();
	}, []);

	const setPermission = (community_id, permission, status) => {
		let permissions = {...props.permissions};
		let community_permissions = permissions[community_id] ?? {};

		community_permissions[permission] = status;
		permissions[community_id] = community_permissions;

		props.setPermissions(permissions);
	}

	const toggleAll = (permission) => {
		let permissions = {...props.permissions};
		
		let total = communities.length;
		let active = jsonToArray(permissions).filter(el => el.value[permission]).length;

		for(let i in communities) {
			let community = communities[i];
			if ( permissions[community.id] === undefined ) permissions[community.id] = {view: false, add: false, edit: false, delete: false};
			permissions[community.id][permission] = active < total;
		}

		props.setPermissions(permissions);
	}

	const openCommunityWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	let rows = [];
	if ( communities ) communities.forEach((el, idx) => {
		let community_permission = props.permissions && props.permissions[el.id] ? props.permissions[el.id] : {};


		if ( props.principal_user ) community_permission = {
			view: true,
			add: true,
			edit: true,
			delete: true
		};

		rows.push(
			<tr key={idx}>
				<td>{el.name} <NavLink to={'/communities/edit/' + el.id + '?popup=true'} onClick={(e) => openCommunityWindow(e)}><i className="bi bi-eye-fill"></i></NavLink></td>
				<td><input type="checkbox" onChange={(e) => setPermission(el.id, 'view', e.target.checked)} checked={community_permission.view ?? false} /></td>
				<td><input type="checkbox" onChange={(e) => setPermission(el.id, 'add', e.target.checked)} checked={community_permission.add ?? false} /></td>
				<td><input type="checkbox" onChange={(e) => setPermission(el.id, 'edit', e.target.checked)} checked={community_permission.edit ?? false} /></td>
				<td><input type="checkbox" onChange={(e) => setPermission(el.id, 'delete', e.target.checked)} checked={community_permission.delete ?? false} /></td>
			</tr>
		);
	});

	return (
		<React.Fragment>
			<TableStyled className="table table-sm table-bordered mb-2">
				<thead>
					<tr>
						<th>Comunidad</th>
						<th onClick={() => toggleAll('view')}>Ver</th>
						<th onClick={() => toggleAll('add')}>Crear</th>
						<th onClick={() => toggleAll('edit')}>Modificar</th>
						<th onClick={() => toggleAll('delete')}>Eliminar</th>
					</tr>
				</thead>
				<tbody>
					{ rows }
				</tbody>
			</TableStyled>	
			<FieldSmallComment>Estos permisos se utilizan para que el usuario pueda trabajar con ciertas secciones relacionadas con las comunidades. Por ejemplo: propietarios, inquilinos, propiedades...</FieldSmallComment>
		</React.Fragment>	
	);
}