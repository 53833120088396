import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useSearchParams, NavLink } from 'react-router-dom';
import DocumentsPanel from 'components/DocumentsPanel';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import { CristalLoader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { openPopupWindow } from 'helpers/generic';
import Items from './Items';
import Providers from './Providers';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';

let axiosCancelToken = null;

// Get proxy callback from parent opener to make updates if possible
let parentWindowProxyCallback = window.opener?.PopupProxyCallback;
let parentWindowProxyCallbackProviderEstimates = window.opener?.PopupProxyCallbackProviderEstimates;

export default function ProvidersInvoiceForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const forceCanEdit = queryParams.get('edit') === 'true';

	let [data, setData] = useState({
		date: moment().format('YYYY-MM-DD'),
		community: {
			id: getCommunity()?.id,
			name: getCommunity()?.name
		},
		community_id: getCommunity()?.id,
		items: [],
		base: 0,
		total: 0,
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);
	let [reloadFolder, setReloadFolder] = useState(0);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data);
			parentWindowProxyCallbackProviderEstimates(data);
		};
	}, [data]);

	useEffect(() => {
		if (params.id) {
			const getData = async () => {
				setLoading(true);

				await axios.get('/api/manager/providers-estimates/get/' + params.id, {
					params: {},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
					if ( !forceCanEdit ) setCanEdit(false);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	const deleteEstimate = () => {
		const c = window.confirm('¿Quieres eliminar esta estimate?');
		if ( !c ) return;

		axios.delete('/api/manager/providers-estimates/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.info('Solicitud borrada');
			
			if ( popup ) window.close();
			else navigate('/providers-estimates');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		setLoading(true);

		axios.post('/api/manager/providers-estimates/save' + (data.id ? '/' + data.id : ''), data, {
			cancelToken: axiosCancelToken.token,
		}).then((response) => {
			setReloadFolder(Math.random());
			setData({...response.data.estimate});
			toast.success('Datos guardados');
			if ( goBack ) {
				if ( popup ) {
					data = response.data.estimate; // Fix: prevent send incompleted data when popup is closed. Not a good way, but it solves the problem.
					window.close();
				} else navigate(-1);
			} else if ( !data.id ) {
		    	navigate('/providers-estimates/edit/' + response.data.estimate.id + '?edit=true' +  (popup ? '&popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		}).then(() => {
			setLoading(false);
		});
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectCommunity = (community) => {
		setData({
			...data, 
			community: {...community},
			community_id: community?.id
		});
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}
 

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading === true && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Solicitud de presupuesto</h1>
					<button onClick={() => popup ? window.close() : navigate('/providers-estimates')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<LabeledFrame label="Datos básicos" className="mb-3 mb-md-0">
												<div className="row">
													<div className="col col-static-120">
														<div className="mb-0">
															<CustomInput 
																readOnly={true} 
																label="Nº documento" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('number', e.target.value)} 
																value={data.number ?? ''} 
															/>
															{ errors.number &&
																<div className="invalid-feedback d-block">{ errors.number[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-150">
														<div className="mb-0">
															<CustomInput 
																label="Fecha" 
																type="date" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('date', e.target.value)} 
																value={data.date ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.date &&
																<div className="invalid-feedback d-block">{ errors.date[0] }</div>
															}
														</div>
													</div>	
												</div>	
											</LabeledFrame>
										</div>
										<div className="col-md-6">
											<div className="row">
												<LabeledFrame label="Otros datos">
													<div className="row">
														<div className="col col-static-250">
															<div className="mb-0">
																<EmpoweredSelector
																	load={loadCommunities}
																	onChange={(value) => selectCommunity(value)}
																	timeout={250}
																	label={
																		<div>
																			{data.community?.name ?? ''}&nbsp;
																			{data.community?.id &&
																				<NavLink className="text-decoration-none text-reset" to={'/communities/edit/' + data.community.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
																			}
																		</div>
																	}
																	placeholder="Comunidad"
																	showPlaceholderHelper={true}
																	value={data.community?.id}
																	disabled={!canEdit} 
																/>
																{ errors.community_id &&
																	<div className="invalid-feedback d-block">{ errors.community_id[0] }</div>
																}
															</div>
														</div>		
													</div>	
												</LabeledFrame>
											</div>
										</div>	
										<div className="col-md-12 mt-3">
											<div className="row">
												<Providers 
													data={data}
													setData={setData}
													errors={errors}
													readOnly={!canEdit} 
												/>
											</div>
										</div>	
										<div className="col-md-12 mt-3">
											<Items
												data={data}
												setData={setData}
												errors={errors}
												readOnly={!canEdit} 
											/>
										</div>
										{ data.folder_id && 
											<>
												<div className="col-md-6 mt-3">
													<DocumentsPanel
														className="mb-3"
														title="Documentos solicitud"
														folderId={data.folder_docs_id ?? null}
														community={getCommunity()}
														reloadFolder={reloadFolder}
													/>
												</div>

												<div className="col-md-6 mt-3">
													<DocumentsPanel
														className="mb-3"
														title="Propuestas recibidas"
														folderId={data.folder_proposals_id ?? null}
														community={getCommunity()}
														reloadFolder={reloadFolder}
													/>
												</div>
											</>
										}
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && authUserPermission('delete', data.community)) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteEstimate()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ ((authUserPermission('add', data.community) && !data.id) || (authUserPermission('edit', data.community) && data.id)) &&
												<React.Fragment>
													{ data.id &&
														<div className="dropdown d-inline">
															<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																Acciones
															</button>
															<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>

																<li><hr className="dropdown-divider" /></li>
																<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
															</ActionsContextMenu>
														</div>
													}

													<button 
														className="btn btn-sm btn-primary text-white d-inline ms-3" 
														onClick={() => saveData(false)} 
													>Guardar</button>	

													<button 
														className="btn btn-sm btn-primary text-white d-inline ms-3" 
														onClick={() => saveData()} 
													>Guardar y salir</button>
												</React.Fragment>
											}							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}