import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import PopupLayout from 'layouts/PopupLayout';
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import EmpoweredSelector from 'components/EmpoweredSelector';
import CustomInput from 'components/CustomInput';
import { downloadFile, openPopupWindow } from 'helpers/generic';
import { appendToSession, getFromSession } from 'helpers/session';
import { getCommunity } from 'helpers/community';

const Table = styled.table`
	th,td {
		&:nth-child(1) {
			width: 80px;
			white-space: nowrap;
		}
		
		&:nth-child(2) {
			width: 100px;
			white-space: nowrap;
		}
		
		${props => props.type === 'in' ? `
				&:nth-child(3),
				&:nth-child(4) {
					width: 200px;
					white-space: nowrap;
				}
			` :
			`
				&:nth-child(3) {
					width: 200px;
					white-space: nowrap;
				}
			`
		}

		&:nth-last-child(2) {
			min-width: 150px;
			width: 150px;
			text-align: left !important;
		}

		&:nth-last-child(1) {
			min-width: 40px;
			width: 40px;

			div {
				cursor: pointer;
			}
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function IORegistry(props) {
	const navigate = useNavigate();
	const [queryParams] = useSearchParams();

	const type = props.type;
	const typeName = type === 'in' ? 'entrada' : 'salida';

	let filters = getFromSession('ioregistry-list-' + type);

	let pageNo = queryParams.get('page') ?? (filters?.page ?? 1);
	let user_id = parseInt(queryParams.get('user_id')) > 0 ? queryParams.get('user_id') : (filters?.user_id ?? '');
	let community_id = parseInt(queryParams.get('community_id')) > 0 ? queryParams.get('community_id') : (filters?.community_id ?? '');

	let [registries, setRegistries] = useState({});
	let [sessionLoaded, setSessionLoaded] = useState(false);

	let [search, _setSearch] = useState(queryParams.get('search') ?? (filters?.search ?? ''));
	let [searchFix, setSearchFix] = useState(search);
	let setSearch = (value, timeout = 1000) => {
		// Timeout to ajax
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearchFix(value);
			setPage(1);
		}, timeout);

		// Set search
		_setSearch(value);
		window.sessionStorage.setItem('search', value);
	}

	let [sortDirection, _setSortDirection] = useState(filters?.direction ?? 'desc');
	let setSortDirection = (value) => {
		_setSortDirection(value);
		window.sessionStorage.setItem('sortDirection', value);
	}

	let [sortField, _setSortField] = useState(filters?.sort ?? 'date');
	let setSortField = (value) => {
		_setSortField(value);
		window.sessionStorage.setItem('sortField', value);
	}

	let [community, _setCommunity] = useState({
		id: filters?.community_id ?? community_id,
		name: null
	});
	let setCommunity = (value) => {
		_setCommunity(value);
		setPage(1);
		window.sessionStorage.setItem('community', JSON.stringify(value));
	}

	let [user, _setUser] = useState({
		id: filters?.user_id ?? user_id,
		name: null,
	});
	let setUser = (value) => {
		_setUser(value);
		setPage(1);
		window.sessionStorage.setItem('user', JSON.stringify(value));
	}

	let [skeletonRows, setSkeletonRows] = useState(5);

	let [page, _setPage] = useState(filters?.page ?? pageNo);
	const setPage = (page) => {
		setRegistries((prev) => ({...prev, data: undefined}));
		_setPage(page);
		window.sessionStorage.setItem('page', page);
	}

	let [dateFrom, _setDateFrom] = useState(filters?.date_from ?? null);
	let setDateFrom = (value) => {
		_setDateFrom(value);
		setPage(1);
		window.sessionStorage.setItem('dateFrom', value);
	}

	let [dateTo, _setDateTo] = useState(filters?.date_to ?? null);
	let setDateTo = (value) => {
		_setDateTo(value);
		setPage(1);
		window.sessionStorage.setItem('dateTo', value);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( !type ) {
			navigate('/ioregistry/select-type', {replace: true});
			return; 
		}

		if ( !sessionLoaded ) return;

		const getDataAsync = async () => {
			let params = {
				page: page,
				search: searchFix,
				community_id: community?.id,
				user_id: user?.id,
				sort: sortField,
				direction: sortDirection,
				date_from: dateFrom,
				date_to: dateTo,
				movement: type
			};

			appendToSession('ioregistry-list-' + type, params);

			// Load registries list
			await axios.get('/api/manager/ioregistry/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setRegistries({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getDataAsync();
	}, [sessionLoaded, type, page, searchFix, sortField, sortDirection, community?.id, user?.id, dateFrom, dateTo]);

	useEffect(() => {
		let getDataFromAjax = async () => {
			let user = null;
			let community = null;

			let userError = false;
			let communityError = false;

			if ( user_id ) {
				await axios.get('/api/manager/users/get/' + user_id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					user = {id: response.data.id, name: response.data.name};
				}).catch((error) => {
					userError = true;
					if ( axios.isCancel(error) ) return;
				});	
			}

			if ( community_id ) {
				await axios.get('/api/manager/communities/get/' + community_id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					community = {id: response.data.id, name: response.data.name};
				}).catch((error) => {
					communityError = true;
					if ( axios.isCancel(error) ) return;
				});	
			}

			if ( user ) setUser(user);
			if ( community ) setCommunity(community);

			if (!userError && !communityError) {
				setSessionLoaded(true);
			}
		}
		getDataFromAjax();
	}, [user_id, community_id]);

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectCommunity = (value) => {
		setCommunity({
			id: value?.id,
			name: value?.name
		});
	}

	const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectUser = (value) => {
		setUser({
			id: value?.id,
			name: value?.name
		});
	}

	const setDatesRange = (e, range) => {
		e.preventDefault();

		let dateFrom = null;
		let dateTo = null;
		
		switch(range) {
			case 'today':
				dateFrom = moment().format('YYYY-MM-DD');
				dateTo = moment().format('YYYY-MM-DD');
			break;

			case 'yesterday':
				dateFrom = moment().subtract(1, 'days').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'days').format('YYYY-MM-DD');
			break;

			case 'thisweek':
				dateFrom = moment().startOf('week').format('YYYY-MM-DD');
				dateTo = moment().endOf('week').format('YYYY-MM-DD');
			break;

			case 'lastweek':
				dateFrom = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
			break;

			case 'thismonth':
				dateFrom = moment().startOf('month').format('YYYY-MM-DD');
				dateTo = moment().endOf('month').format('YYYY-MM-DD');
			break;

			case 'lastmonth':
				dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;

			case 'thisyear':
				dateFrom = moment().startOf('year').format('YYYY-MM-DD');
				dateTo = moment().endOf('year').format('YYYY-MM-DD');
			break;

			case 'lastyear':
				dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
			break;

			default:
		}

		setDateFrom(dateFrom);
		setDateTo(dateTo);
	}

	const downloadRegistryFile = (e, registry) => {
		e.preventDefault();
		return downloadFile('/api/manager/ioregistry/download-file/' + registry.id);
	}

	const previewRegistryFile = (e, registry) => {
		e.preventDefault();
		
		// Open popup
        let popup = openPopupWindow('');

        // Set loading
        popup.document.body.innerHTML = '<div class="text-center">Cargando...</div>';

        // Download file
        axios({
            url: '/api/manager/ioregistry/download-file/' + registry.id,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const blobUrl = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
            popup.location.href = blobUrl;
        }).catch((error) => {
            return error;
        });
	}

	const openPopupInfoWindow = (e, url) => {
		e.preventDefault();
		return openPopupWindow(url, 'Enviar', 800, 600);
	}

	return (
		<PopupLayout>
			<section>
				<div className="page-title">
					<h1>Registro de {typeName}</h1>

					<div className="ms-auto">
						<NavLink to={'/ioregistry/select-type'} className="btn btn-sm btn-light">Volver</NavLink>
						<NavLink to={'/ioregistry/' + type + '/add'} className="btn btn-sm btn-warning ms-3">Nueva {typeName}</NavLink>
					</div>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-md-12">
							<div className="card border-0 shadow-sm">
								<div className="card-header bg-white p-3">
									<div className="row">
										<div className="col-md-3 col-xl-2 mb-2">
											<CustomInput label="Buscar" type="search" className="form-control form-control-sm" onChange={(e) => setSearch(e.target.value)} value={search ?? ''} />
										</div>

										<div className="col-md-3 col-xl-2 mb-2">
											<EmpoweredSelector
												load={loadCommunities}
												onChange={(value) => selectCommunity(value)}
												timeout={250}
												label={community?.name ?? ''}
												placeholder="- Comunidad -"
												showPlaceholderHelper={true}
												value={community?.id}
											/>
										</div>

										<div className="col-md-3 col-xl-2 mb-2">
											<EmpoweredSelector
												load={loadUsers}
												onChange={(value) => selectUser(value)}
												timeout={250}
												label={user?.name ?? ''}
												placeholder="- Gestor -"
												showPlaceholderHelper={true}
												value={user?.id}
											/>
										</div>

										<div className="col-md-4 mb-2">
											<div className="input-group input-group-sm">
												<span className="input-group-text">
													Fechas
												</span>
												<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
												<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
												<span className="input-group-btn">
													<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
													<ul className="dropdown-menu dropdown-menu-end">
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'today')}>Hoy</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'yesterday')}>Ayer</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'thisweek')}>Esta semana</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'lastweek')}>Semana pasada</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'thismonth')}>Este mes</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'lastmonth')}>Mes pasado</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'thisyear')}>Este año</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'lastyear')}>Año pasado</a></li>
													</ul>
												</span>
											</div>
										</div>
										<div className="col-md-2 mt-2 mb-2 mt-xl-2">
											{/* <button className="btn btn-sm btn-light" onClick={() => setShowExportModal(true)}>Exportar</button>	 */}
										</div>
									</div>
								</div>
								<div className="card-body p-0 pb-1">
									<div className="table-responsive table-responsive-carded">
										<Table className="table table-hover table-sortable table-carded" type={type}>
											<thead>
												<tr>
													<ThSortable direction={sortDirection} active={sortField === 'number.full'} onClick={() => sortTableClick('number.full')}>Nº</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
													{type === 'in' &&
														<ThSortable direction={sortDirection} active={sortField === 'extra'} onClick={() => sortTableClick('extra')}>Origen</ThSortable>
													}
													<ThSortable direction={sortDirection} active={sortField === 'origin.name'} onClick={() => sortTableClick('origin.name')}>{type === 'in' ? 'Destinatario' : 'Destinatario'}</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'description'} onClick={() => sortTableClick('description')}>Tipo</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'user.name'} onClick={() => sortTableClick('user.name')}>Usuario</ThSortable>
												</tr>
											</thead>
											<tbody>
												{ registries.data?.length > 0 &&
													registries.data.map((el, idx) => {
														let canSendEmail = getCommunity()?.id > 0;

														return ( 
															<React.Fragment key={idx}>
																<tr>
																	<td>{el.number_full}</td>
																	<td>
																		{ moment(el.date).format('DD-MM-YYYY') }
																		<small className="d-block">{ moment('2024-12-11 ' + el.time).format('HH:mm') }</small>
																	</td>
																	{type === 'in' &&
																		<td>{el.extra}</td>
																	}
																	<td>
																		<small>{el.origin_type === 'community' && 'Comunidad'}{el.origin_type === 'external' && 'Externo'}</small>
																		<div>{el.origin_name ?? '-'}</div>
																	</td>
																	<td>{el.description}</td>
																	<td>{el.user?.name ?? '-'}</td>
																	<td>
																		{/* <div ><i className="bi bi-file-fill text-primary me-1"></i> {el.file_name}</div> */}
																		<a href="/" className="btn btn-sm btn-link me-2" onClick={(e) => previewRegistryFile(e, el)}><i className="bi bi-eye"></i></a>
																		<div className="dropdown d-inline-block">
																			<button className="btn btn-sm btn-table p-0 px-1 text-primary" type="button" data-bs-toggle="dropdown" onClick={(e) => e.stopPropagation()}>
																				<i className="bi bi-three-dots"></i>
																			</button>
																			<ul className="dropdown-menu">
																				<li><NavLink className="dropdown-item" to={'/'} onClick={(e) => previewRegistryFile(e, el)}>Ver</NavLink></li>
																				<li><NavLink className="dropdown-item" to={'/'} onClick={(e) => downloadRegistryFile(e, el)}>Descargar</NavLink></li>
																				<li className={(!canSendEmail ? 'disabled' : '')}><NavLink className={"dropdown-item " + (!canSendEmail ? 'disabled' : '')} to={'/emails/add?attach_ioregistry_id=' + el.id + '&subject=' + el.file_name} onClick={(e) => openPopupInfoWindow(e, e.target.href)}>Enviar</NavLink></li>
																				<li><hr className="dropdown-divider" /></li>
																				<li><NavLink className="dropdown-item disabled" to={'/ioregistry/' + type + '/edit/' + el.id} disabled>Editar</NavLink></li>
																			</ul>
																		</div>
																	</td>
																</tr>
															</React.Fragment>
														);
													})
												}

												{ registries.data && !registries.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

												{ registries.data === undefined && <TrSkeleton rows={skeletonRows} columns={7} /> }
											</tbody>
										</Table>
									</div>
								</div>
								<div className="card-footer d-flex justify-content-end" id="footer-fixed">
									<div className="d-inline-block">
										<Paginator
											min={1}
											current={registries?.current_page}
											max={registries?.last_page}
											changeCallback={(page) => setPage(page)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</PopupLayout>
	);
}


