import React, {useState, useEffect, useCallback} from 'react';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import LoggedLayout from "layouts/LoggedLayout";
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import Short from './Short';
import Long from './Long';
import Counterparts from './Counterparts';
import ExpensesDistribution from './ExpensesDistribution';
import DetailByAccounts from './DetailByAccounts';
import HideShowModal, { getFromLocalStorage as getVisibleSectionsFromLocalStorage } from './HideShowModal';
import { ucfirst } from 'helpers/generic';

let axiosCancelToken = null;

export default function Balance() {
	let [financialYears, setFinancialYears] = useState([]);
	let [financialYear, setFinancialYear] = useState({
		id: null,
		year: null
	});

	let [financialYearApplied, setFinancialYearApplied] = useState(null);

	let [type, _setType] = useState('short'); // To load the balance on first load
	let setType = (value) => {
		_setType(value);
		setExportBtn(null);
	}
	
	let [typeApplied, setTypeApplied] = useState('short');
	let [visibleSections, setVisibleSections] = useState([]);
	let [showHideShowModal, setShowHideShowModal] = useState(false);
	let [exportBtn, setExportBtn] = useState(null);

	useEffect(() => {
		if ( axiosCancelToken ) axiosCancelToken.cancel();
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [type]);
	
	useEffect(() => {
		const getFinancialYears = async () => {
			await axios.get('/api/manager/financial-years/list', {
				params: {
					community_id: getCommunity()?.id,
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setFinancialYears(response.data);
				setFinancialYear(response.data[0]);

				setFinancialYearApplied(response.data[0]); // To load a balance on first load
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getFinancialYears();
	}, []);

	useEffect(() => {
		setVisibleSections(getVisibleSectionsFromLocalStorage(typeApplied));
	}, [typeApplied]);

	const loadFinancialYears = (input, callback, args) => {
		let financialYears = args.financialYears;

		let formatted = financialYears?.map((el) => {
			return {
				value: el, 
				label: el.year
			};
		});
		callback(formatted);
	}

	const applyFilters = useCallback((financialYear, type) => {
		setFinancialYearApplied(financialYear);
		setTypeApplied(type);
	}, []);

	// useEffect(() => {
	// 	if (firstLoadRef.current || !financialYear?.id || !type) {
	// 		return;
	// 	}

	// 	firstLoadRef.current = true;

	// 	applyFilters(financialYear, type);
	// }, [applyFilters, financialYear, type]);

	if ( !getCommunity()?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Balance</h1>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm" id="card-balance">
						<div className="card-header bg-white p-3">
							<div className="row d-flex align-items-center">
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" value={type} onChange={(e) => { setType(e.target.value); }}>
										<option value="">- Tipo -</option>
										<option value="short">Balance resumido</option>
										<option value="long">Balance detallado</option>
										<option value="counterparts">Contrapartidas</option>
										<option value="expenses-distribution">Distribución de gastos</option>
										<option value="detail-by-accounts">Detalle por cuentas</option>
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadFinancialYears}
										args={{financialYears}}
										onChange={(value) => setFinancialYear(value)}
										timeout={250}
										label={financialYear?.year}
										placeholder="- Ejercicio -"
										value={financialYear?.id}
										noSearcheable={true}
									/>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<button onClick={() => applyFilters(financialYear, type)} className="btn btn-light btn-sm">Aplicar</button>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
								<div className="col-md-4 text-end mb-2 mt-2 mb-md-0 mt-md-0">
									{ typeApplied === 'long' &&
										<button onClick={() => setShowHideShowModal(true)} className="btn btn-light btn-sm">Mostrar u ocultar secciones</button>
									}
		
									{exportBtn && exportBtn}				
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							{ financialYearApplied?.year &&
								<>
									{typeApplied === 'short' && 
										<Short 
											year={financialYearApplied.year} 
											setExportBtn={setExportBtn}
										/>
									}

									{typeApplied === 'long' && 
										<Long 
											year={financialYearApplied.year} 
											visibleSections={visibleSections} 
											setExportBtn={setExportBtn}
										/>
									}

									{typeApplied === 'counterparts' && 
										<Counterparts 
											year={financialYearApplied.year} 
											visibleSections={visibleSections} 
											setExportBtn={setExportBtn}
										/>
									}

									{typeApplied === 'expenses-distribution' && 
										<ExpensesDistribution 
											year={financialYearApplied.year}
											setExportBtn={setExportBtn}
										/>
									}	
									
									{typeApplied === 'detail-by-accounts' && 
										<DetailByAccounts 
											year={financialYearApplied.year}
											setExportBtn={setExportBtn}
										/>
									}
								</>
							}
						</div>
					</div>
				</div>
			</section>
			{ showHideShowModal && 
				<HideShowModal
					type={typeApplied}
					fields={ require('./' + ucfirst(typeApplied)).sections ?? {} }
					closeCallback={() => {
						setShowHideShowModal(false);
						setVisibleSections( getVisibleSectionsFromLocalStorage(typeApplied) );
					}}
				/> 
			}
		</LoggedLayout>
	);
}