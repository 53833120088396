import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { getCommunity, authUserPermission } from "helpers/community";
import { loader } from "helpers/generic";
import AddTitleModal from "./AddTitleModal";
import AddExpenseModal from "./AddExpenseModal";

let axiosCancelToken = null;

const CounterPartsSelectorStyles = styled.div`
    position: relative;
    user-select: none;

    &.disabled {
        pointer-events: none;
    }   

    .acm-label {
        cursor: pointer;
        background: white;
        user-select: none;

        &:hover {
            background: var(--bs-gray-100);
        }     
    }

    & > .dropdown-menu {
        z-index: 99;

        &.show {
            display: inline-block;
            position: absolute;
        }

        & > .dropdown-item,
        & > .dropdown-item .submenu > .dropdown-item {

            &.active, &:active {
                & > div {
                    color: var(--bs-dark);
                }
            }

            & > div {
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                i {
                    margin-left: 10px;
                    line-height: 0;
                    color: var(--bs-gray-600);
                }
            }
        }

        .dropdown-item {
            cursor: pointer;
            /* position: relative; */

            &.selected {
                background: var(--bs-gray-200);
            }
        }

        .dropdown-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-weight: bold;
            color: var(--bs-gray-dark);
            background: white !important;
            cursor: default;
        }
    }



    /* ============ submenu desktop view ============ */
    @media all and (min-width: 992px) {
        .dropdown-menu {
            position: relative;
        }
        /* .dropdown-menu li { position: relative; 	} */
        .dropdown-item > .submenu { 
            position: absolute;
            left: calc(100% + 1.5px); 
            top: 0;
            /* padding-top: 0; */
            /* margin-bottom: 30px; */
        }
    }	
    /* ============ submenu desktop view .end// ============ */

    /* ============ submenu small devices ============ */
    @media (max-width: 991px) {
        .dropdown-menu > .dropdown-menu{
            margin-left:0.7rem; 
            margin-right:0.7rem; 
            margin-bottom: .5rem;
        }
    }	
    /* ============ submenu small devices .end// ============ */
`;


/**
 * NOTE FOR props.expense
 * 
 * This prop must have to work:
 *      expense
 *      expense -> title
 *      expense -> group
 *      expense -> group -> estimate
 */
export default function CounterPartsSelector(props) {
    let community_id = props.communityId ?? getCommunity().id;
    let onChange = props.onChange;
    let disabled = props.disabled;
    let year = props.year;
    let canAdd = props.canAdd ?? true;

    let ref = useRef(null);

    let [expense, setExpense] = useState(null);
    let [opened, _setOpened] = useState(false);
    let setOpened = (status) => {
        _setOpened(status);
        if ( !status ) setSelectedEstimateIdx(null);
    }

    let [estimatesLoading, setEstimatesLoading] = useState(false);
    let [estimates, setEstimates] = useState(null);

    let [selectedEstimateIdx, _setSelectedEstimateIdx] = useState(null);
    let setSelectedEstimateIdx = (idx) => {
        _setSelectedEstimateIdx(idx);
        if (idx === null) setSelectedGroupIdx(null);
    }
    let [selectedGroupIdx, _setSelectedGroupIdx] = useState(null);
    let setSelectedGroupIdx = (idx) => {
        _setSelectedGroupIdx(idx);
        setSelectedTitleIdx(null);
    }
    let [selectedTitleIdx, setSelectedTitleIdx] = useState(null);

    let label = <>Seleccionar <i className="bi bi-arrow-right"></i></>;
    if ( expense ) {
        label = <>
            {'P. ' + (expense.estimategroup.estimate.type === 'ordinary' ? 'Ordinario' : 'Extraordinario') + ' ' + expense.estimategroup.estimate.number_full}
            <i className="bi bi-arrow-right mx-1"></i>
            {expense.title.denomination}
            <i className="bi bi-arrow-right mx-1"></i>
            {expense.estimategroup.denomination}
            <i className="bi bi-arrow-right mx-1"></i>
            {expense.denomination}
        </>;
    }

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    useEffect(() => {
        if ( !props.expense ) return;
        setExpense(props.expense);
    }, [props.expense]);

    useEffect(() => {
		let clickOutsideListener = (event) => {
            let element = event.target;

            let isOutside = true;
            do {
                isOutside = !element.classList.contains('acm-inside');
                if ( !isOutside ) break;

                element = element.parentElement;
            } while (element);

            if ( isOutside ) setOpened(false);
        }
        document.addEventListener('click', clickOutsideListener);
	
		return function cleanup() {
            document.removeEventListener('click', clickOutsideListener);
        }
	}, []);

    const toggleDropdown = () => {
        let newStatus = !opened;
        setOpened(newStatus);

        if ( newStatus ) {
            loadEstimates();
        }
    }

    const loadEstimates = async () => {
        setEstimatesLoading(true);
        	axios.get('/api/manager/estimates/list', {
			params: {
				community_id: community_id,
				no_paginate: true,
				status: 1,
                year: year
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
            setEstimates(response.data);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
        setEstimatesLoading(false);
    }

    const selectExpense = (expense) => {
        setExpense(expense);
        onChange(expense);
        setOpened(false);
    }

    const setSelectedEstimateIdxHandler = (e, idx) => {
        if ( !e.currentTarget.classList.contains('estimate-item') ) return;
        e.stopPropagation();
        setSelectedEstimateIdx(idx);
    }

    const setSelectedGroupIdxHandler = (e, idx) => {
        if ( !e.currentTarget.classList.contains('group-item') ) return;
        e.stopPropagation();
        setSelectedGroupIdx(idx);
    }

    const setSelectedTitleIdxHandler = (e, idx) => {
        if ( !e.currentTarget.classList.contains('title-item') ) return;
        e.stopPropagation();
        setSelectedTitleIdx(idx);
    }

	return (
        <>
            <CounterPartsSelectorStyles className={'counterparts-selector acm-inside ' + (disabled ? 'disabled' : '')} onContextMenu={(e) => {e.stopPropagation(); e.preventDefault();}}>
                <div className="acm-label form-control" onClick={toggleDropdown}>
                    {label ?? <>&nbsp;</>}
                </div>

                <ul ref={ref} className={'dropdown-menu ' + (opened ? 'show' : '')}>
                    {estimatesLoading && loader}
                    {estimates &&
                        <>
                            <li className="dropdown-header">Presupuestos</li>
                            {estimates.map((el, idx) => {
                                return (
                                    <li key={'estimate-'+el.id} className={'dropdown-item estimate-item ' + (selectedEstimateIdx === idx ? 'selected' : '')}  onClick={(e) => setSelectedEstimateIdxHandler(e, idx)}>
                                        <div>
                                            {'P. ' + (el.type === 'ordinary' ? 'Ordinario' : 'Extraordinario') + ' ' + el.number_full}
                                            <i className="bi bi-caret-right-fill"></i>
                                        </div>
                                        <ul className={'submenu dropdown-menu ' + (selectedEstimateIdx === idx ? 'show' : '')}>
                                            <li className="dropdown-header">Grupos de reparto</li>
                                            {el.groups?.length > 0 ?
                                                el.groups.map((gEl, gIdx) => {
                                                    return (
                                                        <li key={'estimategroup-'+gEl.id} className={'dropdown-item group-item ' + (selectedGroupIdx === gIdx ? 'selected' : '')} onClick={(e) => setSelectedGroupIdxHandler(e, gIdx)}>
                                                            <div>
                                                                {gEl.denomination}
                                                                <i className="bi bi-caret-right-fill"></i>
                                                            </div>

                                                            <ul className={'submenu dropdown-menu ' + (selectedGroupIdx === gIdx ? 'show' : '')}>
                                                                <li className="dropdown-header">
                                                                    <span>Familias</span> 
                                                                    { (authUserPermission('edit') && canAdd) &&
                                                                        <AddTitleModal
                                                                            communityId={community_id}
                                                                            groupId={gEl.id}
                                                                            closeCallback={() => {
                                                                                loadEstimates();
                                                                            }}
                                                                        />
                                                                    }
                                                                </li>
                                                                {gEl.titles?.length > 0 ?
                                                                    gEl.titles.map((tEl, tIdx) => {
                                                                        return (
                                                                            <li key={'estimategroup-'+tEl.id} className={'dropdown-item title-item ' + (selectedTitleIdx === tIdx ? 'selected' : '')} onClick={(e) => setSelectedTitleIdxHandler(e, tIdx)}>
                                                                                <div>
                                                                                    {tEl.denomination}
                                                                                    <i className="bi bi-caret-right-fill"></i>
                                                                                </div>

                                                                                <ul className={'submenu dropdown-menu ' + (selectedTitleIdx === tIdx ? 'show' : '')}>
                                                                                    <li className="dropdown-header">
                                                                                        <span>Gastos</span>
                                                                                        { (authUserPermission('edit') && canAdd) &&
                                                                                            <AddExpenseModal
                                                                                                communityId={community_id}
                                                                                                titleId={tEl.id}
                                                                                                contableAccountParentId={tEl.contableaccount_id}
                                                                                                contableAccountsExceptIds={tEl.expenses.map(el => el.account?.id)}
                                                                                                closeCallback={() => {
                                                                                                    loadEstimates();
                                                                                                }}
                                                                                            />
                                                                                        }   
                                                                                    </li>
                                                                                    {tEl.expenses?.length > 0 ?
                                                                                        tEl.expenses.map(eEl => {
                                                                                            return (
                                                                                                <li 
                                                                                                    key={'estimategroup-'+eEl.id} 
                                                                                                    className="dropdown-item expense-item" 
                                                                                                    onClick={() => selectExpense({
                                                                                                        ...eEl, // Expense itself
                                                                                                        estimategroup: { // Group
                                                                                                            ...gEl,
                                                                                                            estimate: {...el} // Group -> estimate
                                                                                                        },
                                                                                                        title: {...tEl} // Title
                                                                                                    })
                                                                                                }>
                                                                                                    <div>
                                                                                                        {eEl.denomination}
                                                                                                    </div>
                                                                                                </li>
                                                                                            );
                                                                                        })
                                                                                        :
                                                                                        <li className="dropdown-item">No hay gastos</li>
                                                                                    }
                                                                                </ul>
                                                                            </li>
                                                                        );
                                                                    })
                                                                    :
                                                                    <li className="dropdown-item">No hay familias</li>
                                                                }
                                                            </ul>
                                                        </li>
                                                    );
                                                })
                                                :
                                                <li className="dropdown-item">No hay grupos de reparto</li>
                                            }
                                        </ul>
                                    </li>
                                );
                            })}
                        </>
                    }
                </ul>
            </CounterPartsSelectorStyles>
        </>
	);
}